export const LoginTab = {
  LOGIN: 0,
  REGISTER_OPTION: 1,
  REGISTER_PART_1: 2,
  REGISTER_PART_2: 3,
  REGISTER_PART_3: 4,
  FORGOT_PASSWORD_PART_1: 5,
  FORGOT_PASSWORD_PART_2: 6,
  FORGOT_PASSWORD_PART_3: 7,
  LOGIN_PHONE_AUTH_STEP_1: 8,
  LOGIN_PHONE_AUTH_STEP_2: 9,
  GOOGLE_REGISTER: 10,
  LOGIN_CHOOSE: 11,
};
