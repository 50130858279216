import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { useAuth } from "../../auth/components/AuthInit";
import AppDownloadButton from "./AppDownloadButton";

const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const { logout } = useAuth();
  const [swalProps, setSwalProps] = useState({});
  const value = {};

  useEffect(() => {
    const handleTokenChange = () => {
      setSwalProps({
        show: true,
        icon: "error",
        text: "Please avoid logging in with the same account\n请避免使用同一账号登入\nSila elakkan log masuk dengan  akaun yan sama",
      });
    };

    window.addEventListener("sessionLost", handleTokenChange);

    return () => {
      window.removeEventListener("sessionLost", handleTokenChange);
    };
  }, []);

  return (
    <>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
          logout();
        }}
      />
      <MasterLayoutContext.Provider value={value}>
        <div className="rc279-content">
          <Outlet />
          {/* <AppDownloadButton /> */}
        </div>
      </MasterLayoutContext.Provider>
    </>
  );
};
