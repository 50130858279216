import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { fetchIPAddress, login, loginChoose } from "../../api/loginapi";
import { AddLoginAccDialog } from "./addLoginAcc/AddLoginAccDialog";
import SweetAlert2 from "react-sweetalert2";
import { useAuth } from "../AuthInit";

export const LoginChooseTab = ({}) => {
  const intl = useIntl();
  const { saveAuth, setCurrentUser } = useAuth();
  const [memberTelegramLists, setMemberTelegramLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  useEffect(() => {
    const queryParams = {
      action: "membertelegramvisit",
      telegramid: sessionStorage.getItem("telegramID") || "",
    };
    loginChoose(queryParams).then((res) => {
      setMemberTelegramLists(res.memberTelegramLists || []);
    });
  }, []);

  const loginWithClick = (username, password, setSwalProps) => {
    setIsLoading(true);
    fetchIPAddress()
      .then((res) => {
        const queryParams = {
          action: "memberlogin",
          username: username,
          password: password,
          language: intl.locale,
          fcmnotificationid: "",
          ipaddress: res.ip,
        };

        login(queryParams)
          .then((resp) => {
            setIsLoading(false);
            if (parseInt(resp.code) === 0) {
              saveAuth(resp);
              setCurrentUser(resp);
            } else {
              setSwalProps({
                show: true,
                icon: "error",
                title: intl.formatMessage({ id: "error" }),
                text: resp.message,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const renderRegister = () => {
    return (
      <div>
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <AddLoginAccDialog
          setSwalProps={setSwalProps}
          setMemberTelegramLists={setMemberTelegramLists}
        />
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: "white" }}>
            {intl.formatMessage({ id: "chooseLoginAccount" })}
          </h3>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            overflowY: "auto",
            height: "85vh",
            justifyContent: "center",
          }}
        >
          {memberTelegramLists.map((member, index) => (
            <div
              key={index}
              style={{
                width: "30%",
                aspectRatio: "1",
                maxHeight: "30vw",
                margin: "5px",
                background: "#282c34",
                color: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                cursor: isLoading ? "not-allowed" : "pointer",
                pointerEvents: isLoading ? "none" : "auto",
                opacity: isLoading ? 0.6 : 1,
              }}
              onClick={() =>
                !isLoading &&
                loginWithClick(member.username, member.password, setSwalProps)
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px", // Space between the image and text
                }}
              >
                <img
                  src={member.iconurl}
                  alt="Member Icon"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {member.username || "Member Name"}
              </div>
            </div>
          ))}
          <div
            style={{
              width: "30%",
              aspectRatio: "1",
              maxHeight: "30vw",
              margin: "5px",
              background: "#282c34",
              color: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column", // Align content vertically
              justifyContent: "center", // Vertically center content
              alignItems: "center", // Horizontally center content
              padding: "10px",
            }}
            data-bs-toggle="modal"
            data-bs-target="#addLoginModal"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
              }}
            >
              <i className="bi bi-plus-lg" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ width: "100%" }}>{renderRegister()}</div>
    </div>
  );
};
