import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as actions from "../../../../redux/SettingAction";
import { CheckIsNull } from "../../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { ApiStatusTypeEnum } from "../../../../../../enumeration/ApiStatusTypeEnum";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { DepositTelcoForm } from "./DepositTelcoForm";

export const DepositTelcoDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const receiptRef = useRef();
  const intl = useIntl();
  const navigate = useNavigate();
  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedTelco, setSelectedTelco] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [open, setOpen] = useState(false);
  const [newPayment, setNewPayment] = useState("");
  const [newImg, setNewImg] = useState("");
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getTelcoDepositUI());
    dispatch(actions.getDepositWalletUI({ action: "topupwalletui" })).then(
      (resp) => {
        resp = resp.data;
        setDepositToLastGame(!CheckIsNull(resp.companycode));
      }
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handlNew = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari || sessionStorage.getItem("platform") === "telegram") {
      console.log(newPayment);
      window.open(`${newPayment}&imageurl=${newImg}`);
      setOpen(false);
    } else {
      window.open(`${newPayment}&imageurl=${newImg}`);
      setOpen(false);
    }
  };

  const saveValues = (queryParams) => {
    const pickedTelco = settingState.depositTelcoUI.telcoLists[selectedTelco];

    const newQueryParams = {
      telcocode: pickedTelco.telcocode,
      code: pickedTelco.telcoSupport[selectedType].code,
      type: pickedTelco.telcoSupport[selectedType].type,
      platform: "mobile",
      companycode: !CheckIsNull(companyCode)
        ? companyCode
        : depositToLastGame &&
          !CheckIsNull(settingState.depositWalletUI.companycode)
        ? settingState.depositWalletUI.companycode
        : "",
    };

    dispatch(actions.submitTelcoDepositRequest(newQueryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setNewPayment(resp.paymentlink);
        setNewImg(resp.imageurl);
        if (sessionStorage.getItem("platform") === "mobile") {
          const paymentLink = resp.paymentlink;
          console.log(`paymentTelco:${paymentLink}`);
          sessionStorage.setItem("paymentLink", paymentLink);
          // navigate("/setting/deposit/payment");
        } else {
          setOpen(true);
        }
        if (dismissBtnRef) {
          dismissBtnRef.current.click();
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      {settingState.depositTelcoUI && settingState.depositWalletUI ? (
        <>
          {" "}
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "gateway" })}
          </div>
          <div className="d-flex mb-2 text-font2">
            {settingState.depositTelcoUI.telcoLists.map((telco, index) => (
              <div key={telco.telcocode} className="cursor-pointer me-4">
                <div
                  onClick={() => {
                    setSelectedTelco(index);
                  }}
                >
                  <div
                    className={`d-flex flex-column align-items-center menu-item`}
                    style={{
                      background:
                        selectedTelco === index ? "#f5be09" : "#2e323a",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        color: selectedTelco === index ? "black" : "white",
                        textAlign: "center",
                      }}
                    >
                      {telco.telconame}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "chooseATelco" })}
          </div>
          <div className="row">
            {settingState.depositTelcoUI.telcoLists[
              selectedTelco
            ].telcoSupport.map((telco, index) => (
              <div
                key={telco.code}
                className="col-md-3 mb-2"
                style={{ width: "50%", margin: "0 auto", color: "white" }}
              >
                <div
                  className={`card cursor-pointer ${
                    selectedType === index ? "bank-active" : ""
                  }`}
                  style={{
                    color: selectedType === index ? "#f5be09" : "white",
                    background: "#1c1e23",
                  }}
                >
                  <div
                    className="card-body"
                    style={{ background: "rgba(0,0,0,0.2)" }}
                    onClick={() => {
                      setSelectedType(index);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item ${
                        selectedType === index ? "show fw-bold" : ""
                      }`}
                    >
                      <img
                        src={telco.imageurl}
                        alt={telco.name}
                        style={{ width: "100px" }}
                      />
                      <div
                        className="d-flex align-items-center justify-content-center small"
                        style={{ height: "40px" }}
                      >
                        <div className="text-center text-font2">
                          {telco.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {settingState.depositWalletUI &&
            CheckIsNull(companyCode) &&
            !CheckIsNull(settingState.depositWalletUI.content) && (
              <div className="form-check text-font2">
                <input
                  className="form-check-input"
                  id="depositWalletUI"
                  type="checkbox"
                  checked={depositToLastGame}
                  onChange={(e) => {
                    setDepositToLastGame(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="depositWalletUI"
                  dangerouslySetInnerHTML={{
                    __html: settingState.depositWalletUI.content.replace(
                      /\*(.*?)\*/g,
                      "<span class='text-danger fw-bold text-font3'>$1</span>"
                    ),
                  }}
                />
              </div>
            )}
          <button
            type="button"
            disabled={settingState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => saveValues()}
          >
            <div className="d-flex align-items-center justify-content-center text-font3">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
          <Dialog open={open} onClose={handleClose}>
            <div
              className="text-font3"
              style={{
                padding: "20px",
                backgroundColor: "#1c1e23",
                color: "white",
              }}
            >
              <p> {intl.formatMessage({ id: "openNewTab1" })}</p>
              <div className="mb-1 d-flex">
                <i className="bi bi-dot"></i>
                <div>{intl.formatMessage({ id: "openNewTab2" })}</div>
              </div>
              <div className="mb-1 d-flex">
                <i className="bi bi-dot"></i>
                <div>{intl.formatMessage({ id: "openNewTab3" })}</div>
              </div>

              <div
                className="d-flex mt-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <div style={{ color: "red" }} onClick={() => setOpen(false)}>
                  {intl.formatMessage({ id: "cancel" })}
                </div>
                <div style={{ color: "#f5be09" }} onClick={handlNew}>
                  {intl.formatMessage({ id: "confirm" })}
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <Skeleton className="w-100" height="500px" />
      )}
    </>
  );
};
