import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    sessionLost: "Session Lost. Please login again",
    errorOccurContactAdmin:
      "Error occur. Please contact administrator to help.",
    marquee_home: "Hello This From left to right",
    welcome: "Welcome,",
    cash: "Cash",
    bonus: "Bonus",
    point: "Point",
    deposit: "Deposit",
    transfer: "Transfer",
    withdraw: "Withdraw",
    load_more: "Load More",
    game: "Game",
    wallet: "Wallet",
    promotion: "Promotion",
    result: "Result",
    profile: "Profile",
    gameid: "Game Id",
    language: "Language",
    reset_password: "Reset Password",
    member_transfer: "Member Transfer",
    invite_friend: "Invite Friend",
    choose_language: "Choose Language",
    close: "Close",
    transfer_amount: "Transfer Amount",
    main_wallet: "Main Wallet",
    hide_wallet: "Hide Wallet",
    lock_wallet: "Lock Wallet",
    qr: "QR",
    bank: "Bank",
    paying_method: "Paying Method",
    deposit_amount: "Deposit Amount",
    target: "Target",
    copy: "Copy",
    address: "Address",
    top_up: "Top Up",
    balance: "Balance",
    my_balance: "My Balance",
    welcomeBack: "Welcome Back!",
    memberId: "Member ID",
    username: "Username",
    password: "Password",
    forgetpassword: "Forget Password",
    login: "Log In",
    or: "Or",
    loginWithGoogle: "Log In With Google",
    dontHaveAccount: "Didn't Have Account ?",
    signUp: "Sign Up",
    chooseRegisterMethod: "Choose Register Method",
    registerWithGoogle: "Register With Google",
    orRegisterWith: "Or Register With",
    registerWithPhone: "Register With Phone",
    alreadyHaveAccount: "Already Have Account?",
    phoneNo: "Phone No.",
    otp: "OTP",
    refferalCode: "Refferal Code",
    verificationCode: "Verification Code",
    confirmPassword: "Confirm Password",
    register: "Register",
    next: "Next",
    usernameRequired: "Username is Required",
    passwordRequired: "Password is Required",
    forgetpassword: "Forget Password",
    referralCodeRequired: "Referral Code Required",
    verifyCodeRequired: "Verification Code Required",
    requestOTP: "Request OTP",
    phoneNoRequired: "Phone No. Required",
    otpHasSended:
      "Thank you for providing your phone number. We've just sent a one-time password (OTP) to the number",
    otpRequired: "OTP Required",
    currency: "Currrency",
    currencyRequired: "Currency Required",
    error: "Error",
    home: " Home",
    report: "Report",
    title: "Title",
    search: "Search",
    noRecordFound: "No Record Found",
    personal: "Personal",
    pointshop: "Point Shop",
    exchange: "Exchange",
    inbox: "Inbox",
    contact_us: "Contact Us",
    logout: "Logout",
    history: "History",
    play: "Play",
    playNow: "Play Now",
    promotion: "Promotion",
    detail: "Detail",
    copySuccessfully: "Copy Successfully",
    mandarin: "Mandarin",
    english: "English",
    malay: "Malay",
    indo: "Indo",
    registerDate: "Register Date",
    submit: "Submit",
    newPassword: "New Password",
    oldPassword: "Old Password",
    editPassword: "Edit Password",
    oldPasswordRequired: "Old Password Required",
    newPasswordRequired: "New Password Required",
    confirmPasswordRequired: "Confirm Password Required",
    transaction: "Transaction",
    pointShop: "Point Shop",
    unlimitedClaim: "Unlimited Claim",
    duration: "Duration",
    limitedClaim: "Limited Claim",
    available: "Available",
    claimBonus: "Claim Bonus",
    atLeast1toClaim: "At Least 1 to Claim",
    all: "All",
    amount: "Amount",
    receiverUsername: "Receiver Username",
    receiverUsernameRequired: "Receiver Username Required",
    amountRequired: "Amount Required",
    memberTransferDscription_1:
      "Please ensure accurate details when transferring balance to another user. Double-check recipient information, to prevent any transfer errors. Your diligence ensures a seamless and secure transaction experience for both you and the recipient.",
    memberTransferDscription_2: "Thank you for your attention to detail!",
    attention: "Attention",
    memberTransfer: "Member Transfer",
    noWithdrawalAccount:
      "To ensure seamless transactions and secure withdrawals, we kindly request you to add a withdrawal account at your profile. This step is essential for processing your withdrawals efficiently and safeguarding your funds.",
    withdrawalDescriptionTitle: "How withdraw works :",
    withdrawalDescriptionTitle_1: "Select Your Withdrawal Account",
    withdrawalDescription_1:
      "Choose the preferred withdrawal account from the options available in your withdrawal account list.",
    withdrawalDescriptionTitle_2: "Enter Withdrawal Details",
    withdrawalDescription_2: "Input the amount you wish to withdraw",
    withdrawalDescriptionTitle_3: "Confirm and Process",
    withdrawalDescription_3:
      "Double-check your details and hit 'Confirm' to initiate the withdrawal process.",
    withdrawalNoteTitle: "Please note",
    withdrawalNoteDescription_1:
      "Withdrawals may take a certain processing time depending on your chosen method and financial institutions involved.",
    withdrawalNoteDescription_2:
      "Ensure your withdrawal details are accurate to prevent any delays in receiving your funds.",
    withdrawalNoteDescription_3:
      "If you encounter any issues or have questions, our support team is here to assist you.",
    withdrawalNoteDescription_4:
      "Thank you for choosing us for your financial transactions. We strive to make your withdrawal experience as smooth as possible!",
    goToProfile: "Redirect to Add Bank",
    pending: "Pending",
    withdrawalAccountList: "Withdrawal Account list",
    gameTransfer: "Game Transfer",
    clickTransfer: "Click Transfer",
    transferAllToWallet: "Transfer All To Wallet",
    walletBalance: "Wallet Balance",
    canceled: "Canceled",
    reject: "Reject",
    approve: "Approve",
    onProgress: "On Progress",
    request: "Request",
    days: "Days",
    companyList: "Company List",
    viewResult: "View Result",
    betNow: "Bet Now",
    payout: "Payout",
    report: "Report",
    prize: "Prize",
    special: "Special",
    consolation: "Consolation",
    refreshIn: "Refresh in",
    chooseABank: "Choose a bank",
    chooseSeason: "Choose Season",
    chooseCompany: "Choose Company",
    number: "Number",
    numberList: "Number List",
    big: "Big",
    small: "Small",
    "4a": "4A",
    betType: "Bet Type",
    add: "Add",
    numberRequired: "Number is required",
    sumMin1: "Sum of Big, Small, 4A must be at least 1",
    numberMin4: "Number must be 4 in digit",
    companyRequired: "Company is required",
    total: "Total",
    company: "Company",
    confirmRemoveNumber: "Are you sure you want to remove this number?",
    confirmRemoveAllNumber: "Are you sure you want to remove all the numbers?",
    confirmSubmitNumber: "Are you sure you want to purchase these numbers?",
    basicLottery: "Basic Lottery",
    specialLottery: "Special Lottery",
    gdLotto: "GD Lotto",
    chooseLotteryType: "Choose Lottery Type",
    slip: "Slip",
    today: "Today",
    yesterday: "Yesterday",
    reorderSuccessfully: "Reorder Successfully",
    voidSuccessfully: "Void Successfully",
    orderId: "Order ID",
    reorder: "Reorder",
    void: "Void",
    detail: "Detail",
    basic: "Basic",
    advance: "Advance",
    totalWin: "Total Win",
    totalBet: "Total Bet",
    confirmVoidSlip: "Are you sure you want to void this slip?",
    confirmReorder: "Are you sure you want to reorder?",
    strikeBig: "Strike Big",
    strikeSmall: "Strike Small",
    strike4a: "Strike 4A",
    estimatePrice: "Est. Price",
    estimateTotal: "Est. Total",
    season: "Season",
    bet: "Bet",
    win: "Win",
    loading: "Loading",
    date: "Date",
    cancel: "Cancel",
    alert: "Alert",
    purchaseSuccessfully: "Purchase successfully.",
    referenceId: "Reference ID",
    confirm: "Confirm",
    success: "Success",
    chooseABank: "Choose A Bank",
    gateway: "Gateway",
    paymentMethod: "Payment Method",
    paymentGateway: "Payment Gateway",
    atm: "ATM",
    telco: "Telco (Prepaid)",
    telconame: "Telco Name",
    mobilename: "Mobile Name",
    chooseATelco: "Choose A Telco",
    fail: "Fail",
    changePassword: "Change Password",
    resetGamePassword: "Reset Game Password",
    apkDownload: "APK Download",
    createGameIdDescription:
      "Welcome! To initiate the creation of your game ID, simply press the button below. This step will generate your unique identifier, granting access to a world of gaming possibilities. Get ready to embark on your gaming journey with just a click!",
    createGameId: "Create Game ID",
    receiptRequired: "Receipt Required",
    receipt: "Receipt",
    accountNumber: "Account Number",
    accountName: "Account Name",
    bankName: "Bank Name",
    depositAccount: "Deposit Account",
    selected: "Selected",
    depositAccountList: "Deposit Account List",
    requestedDate: "Requested Date",
    bankname: "Bank Name",
    gatewayname: "Gateway Name",
    lottery: "Lottery",
    gameNotFound: "Game Not Found",
    option: "Option",
    changePosition: "Change Position",
    backPage: "Back To Previous Page",
    confirmation: "Confirmation",
    confirmationSwitchRegion_1:
      "By proceeding with the currency switch, please be aware that your current balance will be cleared. This includes any remaining credits, funds, or balances associated with your account. Make sure to review your account status before confirming the currency change",
    confirmationSwitchRegion_2:
      "Are you sure you want to proceed to switch currency to {n} and clear your current balance? If confirm fill in your password and press confirm.",
    switchSuccessfully:
      "Switch currency successfully. To apply the changes and ensure the new settings take effect, please relogin in to your account",
    bankCard: "Bank Card",
    addBank: "Add Bank",
    addBankDescription: "Please bind a bank card first for withdrawal",
    otherBankName: "Other Bank Name",
    otherBankNameRequired: "Other Bank Name Required",
    fullName: "Full Name",
    fullNameRequired: "Full Name Required",
    accountNumRequired: "Account Number Required",
    rate: "Rate",
    chat: "Chat",
    depositRequestSendSuccessfully: "Deposit Request Send Successfully",
    withdrawRequestSendSuccessfully: "Withdraw Request Send Successfully",
    transferSuccessfully: "Transfer Successfully",
    confirmRemoveBank: "Confirm Remove Bank",
    addBankSuccessfully: "Add Bank Successfully",
    deleteBankSuccessfully: "Delete Bank Successfully",
    status: "Status",
    failToRegister: "Fail To Register",
    failToLogin: "Fail To Login",
    tnc: "Terms and Conditions",
    userAgreement: "ES51 User Agreement",
    userAgreementDscription_1:
      'This agreement (hereinafter referred to as the "Agreement") is a contract between you and ES51 regarding the use of services related to this platform. Please read carefully and agree to all terms of this Agreement.',
    accountRegistration: "1. Account registration",
    accountRegistrationDscription_1:
      "You must be over 18 years old to use this platform.",
    accountRegistrationDscription_2:
      "When registering an account, you need to provide true and complete information.",
    accountRegistrationDscription_3:
      "You need to keep your account information safe and not share it with others.",
    accountRegistrationDscription_4:
      "You are responsible for all activities that occur under your account.",
    depositsNWithdrawals: "2. Deposits and Withdrawals",
    depositsNWithdrawalsDscription_1:
      "You can make deposits and withdrawals through various methods provided by this platform.",
    depositsNWithdrawalsDscription_2:
      "We may charge fees for certain deposit and withdrawal methods.",
    depositsNWithdrawalsDscription_3:
      "You need to comply with the platform's deposit and withdrawal rules.",
    bettingRules: "3. Betting rules",
    bettingRulesDscription_1:
      "You can use your account funds to place bets on this platform.",
    bettingRulesDscription_2:
      "You need to ensure that your account has sufficient funds.",
    bettingRulesNoteTitle_1:
      "We reserve the right to place bets with funds that violate these requirements of the Platform Betting Rules.",
    bettingRulesNoteDscription_1:
      "Use arbitrage strategies or other behavior that violates fair game principles;",
    bettingRulesNoteDscription_2:
      "Behavior with obvious betting errors or fraudulent intent;",
    bettingRulesNoteDscription_3:
      "Any other violation of the platform betting rules.",
    bettingRulesNoteTitle_2:
      "We reserve the right to ban accounts that violate our betting rules:",
    bettingRulesNoteDscription_4:
      "Accounts that frequently make unusual bets or group bets;",
    bettingRulesNoteDscription_5:
      "Accounts suspected of money laundering, cashing out and other illegal and criminal activities;",
    bettingRulesNoteDscription_6:
      "Any other violation of the Platform User Agreement.",
    bonusesNPromotions: "4. Bonuses and Promotions",
    bonusesNPromotionsDscription_1: "We may offer bonuses and promotions.",
    bonusesNPromotionsDscription_2:
      "Each bonus and promotion may have specific terms and conditions.",
    bonusesNPromotionsDscription_3:
      "You are required to comply with the relevant terms and conditions to take advantage of bonuses and promotions.",
    playResponsibly: "5. Play Responsibly",
    playResponsiblyDscription_1: "We promote responsible gaming.",
    playResponsiblyDscription_2:
      "We provide deposit limits, time limits and other tools to help you control your gaming behavior.",
    playResponsiblyDscription_3:
      "If you think you may have a game addiction problem, please seek professional help promptly.",
    others: "6. Others",
    othersDscription_1:
      "You may not use the Platform for any illegal or fraudulent purpose.",
    othersDscription_2:
      "We reserve the right to modify this Agreement at any time.",
    othersDscription_3:
      "Your continued use of this platform constitutes your agreement to accept the revised Agreement.",
    othersDscription_4:
      "The final interpretation right of this agreement belongs to ES51",
    sportsbookTitle: "Most reputable online sportsbook in Asia",
    m9bet_description:
      "M9Bet Sports is a sport-betting game provided by the market-leading sportsbook, M9Bet. The renowned online sportsbook has dedicated teams of hundreds of professional traders and customer service representatives that work 24 hours a day year-round to provide competitive odds and the widest variety of sports and activities possible for players around the globe.",
    ibc_description:
      "IBCBet is a sport-betting game provided by IBC sportsbook. IBC sportsbook offers thousands of live games, such as soccer, basketball, tennis, volleyball and more, featuring the fastest customization and the most comprehensive technical support.",
    ebet_description:
      "World Entertainment is a sport-betting game provided by WE sportsbook. WE sportsbook offers betting service for sports events based on real-time global dynamics and market hot topics, including but not limited to basketball, football, eSports, golf, and badminton.",
    downloadapp: "Download App",
    gmail: "Gmail",
    invalidEmailFormat: "Invalid Email Format",
    emailRequired: "Email Required",
    resetSuccess: "Reset Success",
    registerSuccess1:
      "Thank you for choosing us, We have sent the username and password to the registered Gmail: ",
    registerSuccess2:
      " For the security of your account. We hope you can change your password soon.",
    tranferBonusDescription: "Are you sure you want to claim the bonus?",
    changePasswordSuccess: "Password updated successfully.",
    invalidConfirmPassword: "Invalid confirm password",
    gameIdCreateSuccessfully: "Created successfully.",
    incorrectVerificationCode: "Incorrect verification code",
    backErrorDescription:
      "Please note that if you decide to go back, you will be required to repeat all the step again. Are you sure you want to proceed with it?",
    phoneAuth: "Phone Authentication",
    respondDate: "Respond Date",
    depositRequestCancelSuccessfully: "Deposit request cancel successfully.",
    confirmationCancelDeposit:
      "Are you sure you want to cancel deposit request?",
    prove: "Prove",
    rejectReason: "Reject Reason : ",
    confirmationCancelWithdraw:
      "Are you sure you want to cancel withdraw request?",
    withdrawRequestCancelSuccessfully: "Withdraw request cancel successfully.",
    openNewTab: "Redirect To Online Banking",
    openNewTab1: "Using this feature will not provide the following : ",
    openNewTab2: "Daily topup bonus RM5",
    openNewTab3: "Purchase lottery",
    openNewRedirectTab: "Redirect To Agent Page",
    comingSoon: "Coming Soon!",
    team: "Team",
    joindate: "Join Date:",
    noDownline: "Downline Not Found",
    lastwithdrawrejected: "Last Request Rejected",
    redirecttoagent: "Redirect To Agent",
    contactUs: "Contact Us",
    noPaymentMethodFound: "No Payment Method Found",
    chooseLoginAccount: "Choose Login Account",
    addAcc: "Add Account",
    addAccSuccessfully: "Add Account Successfully",
  },

  [LOCALES.CHINESE]: {
    sessionLost: "会话丢失。 请重新登录",
    errorOccurContactAdmin: "发生错误。 请联系管理员寻求帮助。",
    marquee_home: "你好，这是从左到右",
    welcome: "欢迎,",
    cash: "现金",
    bonus: "奖金",
    point: "分数",
    deposit: "存款",
    transfer: "转移",
    withdraw: "提款",
    load_more: "载入更多信息",
    game: "游戏",
    wallet: "钱包",
    promotion: "优惠",
    result: "结果",
    profile: "简介",
    gameid: "游戏 ID",
    language: "语言",
    reset_password: "更改密码",
    member_transfer: "会员转移",
    invite_friend: "邀请朋友",
    choose_language: "更换语言",
    close: "关闭",
    transfer_amount: "转账金额",
    main_wallet: "主钱包",
    hide_wallet: "隐藏钱包",
    lock_wallet: "锁定钱包",
    qr: "QR",
    bank: "银行",
    paying_method: "付款方式",
    deposit_amount: "存款金额",
    target: "目标",
    copy: "复制",
    address: "地址",
    top_up: "充值",
    balance: "余额",
    my_balance: "我的余额",
    welcomeBack: "欢迎回来！",
    memberId: "会员 ID",
    username: "用户名",
    password: "密码",
    forgetpassword: "忘记密码",
    login: "登入",
    or: "或",
    loginWithGoogle: "使用 Google 登录",
    dontHaveAccount: "没有账户？",
    signUp: "注册",
    chooseRegisterMethod: "选择注册方式",
    registerWithGoogle: "使用谷歌邮箱注册",
    orRegisterWith: "或通过以下方式注册",
    registerWithPhone: "通过电话号码注册",
    alreadyHaveAccount: "已有帐户？",
    phoneNo: "电话号码",
    otp: "一次性密码",
    refferalCode: "推荐代码",
    verificationCode: "验证码",
    confirmPassword: "确认密码",
    register: "注册",
    next: "下一步",
    usernameRequired: "用户名是必需的",
    passwordRequired: "密码是必需的",
    forgetpassword: "忘记密码",
    referralCodeRequired: "需要推荐代码",
    verifyCodeRequired: "需要验证码",
    requestOTP: "需要一次性密码",
    phoneNoRequired: "必须填写电话号码",
    otpHasSended:
      "感谢您提供电话号码。 我们刚刚向电话号码 {n} 发送了一次性密码 (OTP)。",
    otpRequired: "需要一次性密码",
    currency: "货币",
    currencyRequired: "需要货币",
    error: "错误",
    home: " 首页",
    report: "报告",
    title: "标题",
    search: "搜索",
    noRecordFound: "未找到记录",
    personal: "个人",
    pointshop: "点数商店",
    exchange: "兑换",
    inbox: "信箱",
    contact_us: "联系我们",
    logout: "登出",
    history: "历史",
    play: "开始游戏",
    playNow: "立即开始游戏",
    promotion: "优惠",
    detail: "Detail",
    copySuccessfully: "复制成功",
    mandarin: "华语",
    english: "英语",
    malay: "马来语",
    indo: "印度尼西亚语",
    registerDate: "注册日期",
    submit: "提交",
    newPassword: "新密码",
    oldPassword: "旧密码",
    editPassword: "更改密码",
    oldPasswordRequired: "旧密码是必需的",
    newPasswordRequired: "新密码是必需的",
    confirmPasswordRequired: "需要确认密码",
    transaction: "交易",
    pointShop: "点数商店",
    unlimitedClaim: "无限索赔",
    duration: "持续时间",
    limitedClaim: "有限索赔",
    available: "可用",
    claimBonus: "领取奖金",
    atLeast1toClaim: "您需要至少有 1.00 才能领取",
    all: "全部",
    amount: "金额",
    receiverUsername: "接收者用户名",
    receiverUsernameRequired: "需要接收者用户名",
    amountRequired: "所需金额",
    memberTransferDscription_1:
      "向其他用户转移余额时，请确保提供准确的详细信息。请仔细核对收款人信息，防止出现任何转账错误。您的勤勉将确保您和收款人都能获得无缝、安全的交易体验。",
    memberTransferDscription_2: "感谢您对细节的关注！",
    attention: "注意事项",
    memberTransfer: "会员转移",
    noWithdrawalAccount:
      "为确保无缝交易和安全提款，我们恳请您在个人资料中添加一个提款账户。这一步骤对于高效处理您的提款和保护您的资金安全至关重要。",
    withdrawalDescriptionTitle: "提款如何进行 :",
    withdrawalDescriptionTitle_1: "选择提款账户",
    withdrawalDescription_1: "从提款账户列表中选择首选提款账户。",
    withdrawalDescriptionTitle_2: "输入提款详细信息",
    withdrawalDescription_2: "输入您希望提取的金额",
    withdrawalDescriptionTitle_3: "确认和处理",
    withdrawalDescription_3:
      "再次核对您的详细信息，然后点击'确认'以启动提款程序。",
    withdrawalNoteTitle: "请注意",
    withdrawalNoteDescription_1:
      "提款可能需要一定的处理时间，具体取决于您选择的方式和相关金融机构。",
    withdrawalNoteDescription_2: "确保您的提款信息准确无误，以防资金延迟到账。",
    withdrawalNoteDescription_3:
      "如果您遇到任何问题或有任何疑问，我们的支持团队将竭诚为您服务。",
    withdrawalNoteDescription_4:
      "感谢您选择我们进行金融交易。我们努力让您的取款体验尽可能顺畅！",
    goToProfile: "前往添加银行",
    pending: "待办的",
    withdrawalAccountList: "提款账户列表",
    gameTransfer: "游戏转移",
    clickTransfer: "点击转账",
    transferAllToWallet: "全部转入钱包",
    walletBalance: "钱包余额",
    canceled: "已取消",
    reject: "拒绝",
    approve: "批准",
    onProgress: "关于进展",
    request: "要求",
    days: "日期",
    companyList: "公司列表",
    viewResult: "查看结果",
    betNow: "立即下注",
    payout: "支付",
    report: "报告",
    prize: "奖项",
    special: "特别奖",
    consolation: "安慰奖",
    refreshIn: "刷新",
    chooseABank: "选择银行",
    chooseSeason: "选择季节",
    chooseCompany: "选择公司",
    number: "号码",
    numberList: "号码列表",
    big: "大",
    small: "小",
    "4a": "4A",
    betType: "投注类型",
    add: "加",
    numberRequired: "必须填写号码",
    sumMin1: "大,小,4A 之和必须至少为 1",
    numberMin4: "号码必须是 4 位数",
    companyRequired: "公司是必须的",
    total: "总计",
    company: "公司",
    confirmRemoveNumber: "您确定要删除这个号码吗?",
    confirmRemoveAllNumber: "你确定要删除所有号码吗?",
    confirmSubmitNumber: "您确定要购买这些号码吗?",
    basicLottery: "基本彩票",
    specialLottery: "特别彩票",
    gdLotto: "豪龙",
    chooseLotteryType: "选择彩票类型",
    slip: "单据",
    today: "今天",
    yesterday: "昨天",
    reorderSuccessfully: "重新订购成功",
    voidSuccessfully: "成功作废",
    orderId: "订单编号",
    reorder: "重新订购",
    void: "废票",
    detail: "详细信息",
    basic: "基本",
    advance: "预付款",
    totalWin: "总赢",
    totalBet: "总下注",
    confirmVoidSlip: "你确定要让这张纸条作废吗?",
    confirmReorder: "您确定要重新订购?",
    strikeBig: "大的赔率",
    strikeSmall: "小的赔率",
    strike4a: "4A的赔率",
    estimatePrice: "预估金额",
    estimateTotal: "估计总算",
    season: "季节",
    bet: "下注",
    win: "赢",
    loading: "加载中",
    date: "日期",
    cancel: "取消",
    alert: "警告",
    purchaseSuccessfully: "购买成功。",
    referenceId: "参考 ID",
    confirm: "确定",
    success: "成功",
    chooseABank: "选择银行",
    gateway: "网关",
    paymentMethod: "付款方式",
    paymentGateway: "支付网关",
    atm: "提款机",
    telco: "电信公司 (预付费)",
    telconame: "电信公司",
    mobilename: "手机名称",
    chooseATelco: "选择电信公司",
    success: "成功",
    fail: "失败",
    changePassword: "更改密码",
    resetGamePassword: "重置游戏密码",
    apkDownload: "APK 下载",
    createGameIdDescription:
      "欢迎光临！要开始创建您的游戏 ID,只需按下下面的按钮。这一步将生成您的唯一标识符,让您进入一个充满可能性的游戏世界。只需轻轻一点，即可开始您的游戏之旅！",
    createGameId: "创建游戏 ID",
    receiptRequired: "需提供收据",
    receipt: "收据",
    accountNumber: "帐号",
    accountName: "账户名称",
    bankName: "银行名称",
    depositAccount: "存款账户",
    selected: "选定",
    depositAccountList: "存款账户列表",
    requestedDate: "申请日期",
    bankname: "银行名称",
    gatewayname: "网关名称",
    lottery: "彩票",
    gameNotFound: "未找到游戏",
    option: "选择权",
    changePosition: "更改位置",
    backPage: "返回上一页",
    confirmation: "确认",
    confirmationSwitchRegion_1:
      "进行货币转换时，请注意您当前的余额将被清空。这包括与您账户相关的任何剩余积分、资金或余额。在确认货币变更之前，请务必查看您的账户状态",
    confirmationSwitchRegion_2:
      "您确定要将货币转换为 {n} 并清空当前余额？如果确认，请填写密码并按确认。",
    switchSuccessfully:
      "成功切换货币。要应用更改并确保新设置生效，请重新登录您的账户",
    bankCard: "银行卡",
    addBank: "添加银行",
    addBankDescription: "提款时请先绑定银行卡",
    otherBankName: "其他银行名称",
    otherBankNameRequired: "需要其他银行名称",
    fullName: "全名",
    fullNameRequired: "必须填写全名",
    accountNumRequired: "所需账号",
    rate: "费率",
    chat: "聊天",
    depositRequestSendSuccessfully: "存款请求发送成功",
    withdrawRequestSendSuccessfully: "提款请求发送成功",
    transferSuccessfully: "转账成功",
    confirmRemoveBank: "确认删除银行",
    addBankSuccessfully: "添加银行成功",
    deleteBankSuccessfully: "删除银行成功",
    status: "状态",
    failToRegister: "注册失败",
    failToLogin: "登入失败",
    tnc: "条款和条件",
    userAgreement: "279RC用户协议",
    userAgreementDscription_1:
      '本协议 (以下简称"协议") 是您与279RC之间就使用本平台相关服务所订立的合约,请您仔细阅读并同意本协议的所有条款。',
    accountRegistration: "1. 账户注册",
    accountRegistrationDscription_1: "您必须年满18周岁才能使用本平台。",
    accountRegistrationDscription_2: "注册账户时，您需提供真实、完整的信息。",
    accountRegistrationDscription_3:
      "您需妥善保管您的账户信息，不得与他人共享。",
    accountRegistrationDscription_4: "您对您账户下发生的所有活动负责。",
    depositsNWithdrawals: "2. 存款和取款",
    depositsNWithdrawalsDscription_1:
      "您可通过本平台提供的多种方式进行存款和取款。",
    depositsNWithdrawalsDscription_2:
      "我们可能会对某些存款和取款方式收取手续费。",
    depositsNWithdrawalsDscription_3: "您需遵守平台的存款和取款规则。",
    bettingRules: "3. 投注规则",
    bettingRulesDscription_1: "您可使用您的账户资金在本平台进行投注。",
    bettingRulesDscription_2: "您需确保您的账户资金充足。",
    bettingRulesNoteTitle_1:
      "我们保留对违反平台投注规则这些要求的资金下注的权利。",
    bettingRulesNoteDscription_1: "使用套利策略或其他违反公平博弈原则的行为;",
    bettingRulesNoteDscription_2: "存在明显投注错误或欺诈意图的行为;",
    bettingRulesNoteDscription_3: "任何其他违反平台投注规则的行为。",
    bettingRulesNoteTitle_2: "我们保留对违反投注规则的账户采取封禁措施的权利:",
    bettingRulesNoteDscription_4: "频繁进行异常投注或团体投注的账户;",
    bettingRulesNoteDscription_5: "涉嫌洗钱、套现等违法犯罪活动的账户;",
    bettingRulesNoteDscription_6: "任何其他违反平台用户协议的行为.",
    bonusesNPromotions: "4. 奖金和促销",
    bonusesNPromotionsDscription_1: "我们可能会提供奖金和促销活动。",
    bonusesNPromotionsDscription_2:
      "每个奖金和促销活动都可能有特定的条款和条件。",
    bonusesNPromotionsDscription_3:
      "您需遵守相关条款和条件才能享受奖金和促销。",
    playResponsibly: "5. 负责任游戏",
    playResponsiblyDscription_1: "我们倡导负责任游戏。",
    playResponsiblyDscription_2:
      "我们提供存款限额、时间限制等工具帮助您控制游戏行为。",
    playResponsiblyDscription_3:
      "若您认为自己可能存在游戏成瘾问题，请及时寻求专业帮助。",
    others: "6. 其他",
    othersDscription_1: "您不得将本平台用于任何非法或欺诈目的。",
    othersDscription_2: "我们保留随时修改本协议的权利。",
    othersDscription_3: "您继续使用本平台即表示您同意接受修订后的协议。",
    othersDscription_4: "本协议最终解释权归279RC所有",
    sportsbookTitle: "亚洲信誉最高的线上体育博彩",
    m9bet_description:
      "M9Bet是一款由市场领先的体育博彩开发商——M9Bet所开发并推出的体育博彩游戏。M9Bet旗下拥有超过百名专业职员和客服专员全年365天备勤，24小时待命为全球玩家提供最高的赔率和最多样的体育种类及赛事。",
    ibc_description:
      "IBCBet是一款由IBC体育开发并推出的体育博彩游戏。IBC体育提供超过一千种直播赛事，如足球、篮球、网球、排球等，以及最快速的个人定制化服务和最全面的技术支持给所有玩家。",
    ebet_description:
      "World Entertainment是一款由WE开发并推出的体育博彩游戏。WE基于实时体育动态和体育市场热点提供相应的博彩服务，其中包括但不仅限于篮球、足球、电竞、高尔夫球和羽毛球等赛事。",
    downloadapp: "下载App",
    gmail: "谷歌邮箱",
    invalidEmailFormat: "邮件格式无效",
    emailRequired: "需要电子邮件",
    resetSuccess: "重置成功",
    registerSuccess1:
      "感谢您选择我们我们已经发送用户名称以及密码至注册 Gmail: ",
    registerSuccess2: " 为了您的账号安全。我们希望您可以尽快更改您的密码",
    tranferBonusDescription: "您确定要领取奖金吗？",
    changePasswordSuccess: "密码更新成功。",
    invalidConfirmPassword: "确认密码无效",
    gameIdCreateSuccessfully: "创建成功。",
    incorrectVerificationCode: "验证码错误",
    backErrorDescription:
      "请注意，如果您决定返回，则需要再次重复所有步骤。您确定要继续吗？",
    phoneAuth: "电话认证",
    respondDate: "答复日期",
    confirmationCancelDeposit: "您确定要取消存款申请吗？",
    depositRequestCancelSuccessfully: "存款申请取消成功。",
    prove: "证明",
    rejectReason: "拒绝原因 : ",
    confirmationCancelWithdraw: "您确定要取消撤回申请吗？",
    withdrawRequestCancelSuccessfully: "提款请求取消成功。",
    openNewTab: "重定向至网上银行",
    openNewTab1: "使用此功能不会提供以下功能 : ",
    openNewTab2: "每日充值红利 RM5",
    openNewTab3: "购买彩票",
    openNewRedirectTab: "重定向至代理页面",
    comingSoon: "即将推出!",
    team: "团队",
    joindate: "加入日期:",
    noDownline: "未找到下线",
    lastwithdrawrejected: "上次请求被拒绝",
    redirecttoagent: "定向到代理页面",
    contactUs: "联系我们",
    noPaymentMethodFound: "未找到付款方式",
    chooseLoginAccount: "选择登录账户",
    addAcc: "添加账号",
    addAccSuccessfully: "成功添加账号",
  },
  [LOCALES.MALAY]: {
    sessionLost: "Sesi Hilang. Sila log masuk semula",
    errorOccurContactAdmin:
      "Ralat berlaku. Sila hubungi pentadbir untuk membantu.",
    marquee_home: "Hello Ini adalah Dari kiri ke kanan",
    welcome: "Selamat Datang,",
    cash: "Tunai",
    bonus: "Bonus",
    point: "Point",
    deposit: "Deposit",
    transfer: "Pindah",
    withdraw: "Pengeluaran",
    load_more: "Memuatkan Lagi",
    game: "Permainan",
    wallet: "Dompet",
    promotion: "Promosi",
    result: "Hasil",
    profile: "Profil",
    gameid: "ID Permainan",
    language: "Bahasa",
    reset_password: "Menetapkan Semula Kata Laluan",
    member_transfer: "Pemindahan Ahli",
    invite_friend: "Jemput Rakan",
    choose_language: "Pilih Bahasa",
    close: "Tutup",
    transfer_amount: "Jumlah Pemindahan",
    main_wallet: "Dompet Utama",
    hide_wallet: "Sembunyikan Dompet",
    lock_wallet: "Kunci Dompet",
    qr: "QR",
    bank: "Bank",
    paying_method: "Cara Membayar",
    deposit_amount: "Jumlah Deposit",
    target: "Sasaran",
    copy: "Salinan",
    address: "Alamat",
    top_up: "Tambah Nilai",
    balance: "Baki",
    my_balance: "Baki Anda",
    welcomeBack: "Selamat kembali!",
    memberId: "ID Ahli",
    username: "Name Pengguna",
    password: "Kata Laluan",
    forgetpassword: "Lupa Kata Laluan",
    login: "Log Masuk",
    or: "Atau",
    loginWithGoogle: "Masuk Dengan Google",
    dontHaveAccount: "Tiada Akaun?",
    signUp: "Daftar",
    chooseRegisterMethod: "Pilih Cara Daftar",
    registerWithGoogle: "Daftar Dengan Google",
    orRegisterWith: "Atau Daftar Dengan",
    registerWithPhone: "Daftar Dengan Telefon",
    alreadyHaveAccount: "Sudah Mempunyai Akaun?",
    phoneNo: "Telefon No.",
    otp: "OTP",
    refferalCode: "Kod Rujukan",
    verificationCode: "Kod Pengesahan",
    confirmPassword: "Sahkan Kata Laluan",
    register: "Daftar",
    next: "Berikutnya",
    usernameRequired: "Nama Pengguna Diperlukan",
    passwordRequired: "Kata Laluan Diperlukan",
    forgetpassword: "Lupa Kata Laluan",
    referralCodeRequired: "Kod Rujukan Diperlukan",
    verifyCodeRequired: "Kod Pengesahan Diperlukan",
    requestOTP: "Minta OTP",
    phoneNoRequired: "Telefon No. Diperlukan",
    otpHasSended:
      "Terima kasih kerana memberikan nombor telefon anda. Kami baru sahaja menghantar kata laluan satu kali (OTP) ke nombor tersebut",
    otpRequired: "OTP Diperlukan",
    currency: "Mata Wang",
    currencyRequired: "Mata Wang Diperlukan",
    error: "Ralat",
    home: " Laman Utama",
    report: "Laporan",
    title: "Tajuk",
    search: "Cari",
    noRecordFound: "Tiada Rekod Ditemui",
    personal: "Peribadi",
    pointshop: "Kedai Point",
    exchange: "Pertukaran",
    inbox: "Peti Masuk",
    contact_us: "Hubungi Kami",
    logout: "Log Keluar",
    history: "Sejarah",
    play: "Main",
    playNow: "Bermain Sekarang",
    promotion: "Promosi",
    detail: "Perincian",
    copySuccessfully: "Salin Berjaya",
    mandarin: "Bahasa Cina",
    english: "Bahasa Inggeris",
    malay: "Bahasa Melayu",
    indo: "Bahasa Indo",
    registerDate: "Tarikh Daftar",
    submit: "Hantar",
    newPassword: "Kata Laluan Baru",
    oldPassword: "Kata Laluan Lama",
    editPassword: "Edit Kata Laluan",
    oldPasswordRequired: "Kata Laluan Lama Diperlukan",
    newPasswordRequired: "Kata Laluan Baru Diperlukan",
    confirmPasswordRequired: "Sahkan Kata Laluan Diperlukan",
    transaction: "Transaksi",
    pointShop: "Kedai Point",
    unlimitedClaim: "Klaim Tanpa Had",
    duration: "Tempoh",
    limitedClaim: "Klaim Terhad",
    available: "Tersedia",
    claimBonus: "Klaim Bonus",
    atLeast1toClaim: "Sekurang-kurangnya 1 untuk Klaim bonus",
    all: "Semua",
    amount: "Jumlah",
    receiverUsername: "Nama Pengguna Penerima",
    receiverUsernameRequired: "Nama Pengguna Penerima Diperlukan",
    amountRequired: "Jumlah Diperlukan",
    memberTransferDscription_1:
      "Sila pastikan butiran yang tepat apabila memindahkan baki kepada pengguna lain. Semak semula maklumat penerima, untuk mengelakkan sebarang ralat pemindahan. Ketekunan anda memastikan pengalaman transaksi yang lancar dan selamat untuk anda dan penerima.",
    memberTransferDscription_2:
      "Terima kasih atas perhatian anda terhadap perincian!",
    attention: "Perhatian",
    memberTransfer: "Pemindahan Ahli",
    noWithdrawalAccount:
      "Untuk memastikan transaksi lancar dan pengeluaran selamat, kami memohon anda untuk menambah akaun pengeluaran pada profil anda. Langkah ini penting untuk memproses pengeluaran anda dengan cekap dan melindungi dana anda.",
    withdrawalDescriptionTitle: "Cara Pengeluaran Berfungsi :",
    withdrawalDescriptionTitle_1: "Pilih Akaun Pengeluaran Anda",
    withdrawalDescription_1:
      "Pilih akaun pengeluaran daripada pilihan yang tersedia dalam senarai akaun pengeluaran anda.",
    withdrawalDescriptionTitle_2: "Masukkan Butiran Pengeluaran",
    withdrawalDescription_2: "Masukkan jumlah yang anda ingin keluarkan",
    withdrawalDescriptionTitle_3: "Sahkan dan Proses",
    withdrawalDescription_3:
      "Semak semula butiran anda dan tekan 'Sahkan' untuk memulakan proses pengeluaran.",
    withdrawalNoteTitle: "Sila ambil perhatian",
    withdrawalNoteDescription_1:
      "Pengeluaran mungkin mengambil masa pemprosesan tertentu bergantung pada kaedah pilihan anda dan institusi kewangan yang terlibat.",
    withdrawalNoteDescription_2:
      "Pastikan butiran pengeluaran anda adalah tepat untuk mengelakkan sebarang kelewatan dalam menerima dana anda.",
    withdrawalNoteDescription_3:
      "Jika anda menghadapi sebarang masalah atau mempunyai soalan, pasukan sokongan kami sedia membantu anda.",
    withdrawalNoteDescription_4:
      "Terima kasih kerana memilih kami untuk transaksi kewangan anda. Kami berusaha untuk menjadikan pengalaman pengeluaran anda selancar mungkin!",
    goToProfile: "Ubah hala ke menambah bank",
    pending: "Yang belum selesai",
    withdrawalAccountList: "Senarai Akaun Pengeluaran",
    gameTransfer: "Pemindahan Permainan",
    clickTransfer: "Klik Pindahkan",
    transferAllToWallet: "Pindahkan Semua Ke Dompet",
    walletBalance: "Baki Dompet",
    canceled: "Batal",
    reject: "Tolak",
    approve: "Lulus",
    onProgress: "Dalam Kemajuan",
    request: "Permintaan",
    days: "Hari",
    companyList: "Senarai Syarikat",
    viewResult: "Lihat Hasil",
    betNow: "Pertaruhan Sekarang",
    payout: "Pembayaran",
    report: "Laporan",
    prize: "Hadiah",
    special: "Istimewa",
    consolation: "Saguhati",
    refreshIn: "Muat semula masuk",
    chooseABank: "Pilih Bank",
    chooseSeason: "Pilih Musim",
    chooseCompany: "Pilih Syarikat",
    number: "Nombor",
    numberList: "Senarai Nombor",
    big: "Besar",
    small: "Kecil",
    "4a": "4A",
    betType: "Jenis Pertaruhan",
    add: "Tambah",
    numberRequired: "Nombor diperlukan",
    sumMin1: "Jumlah Besar, Kecil, 4A mestilah sekurang-kurangnya 1",
    numberMin4: "Nombor mestilah 4 dalam digit",
    companyRequired: "Syarikat diperlukan",
    total: "Jumlah",
    company: "Syarikat",
    confirmRemoveNumber: "Adakah anda pasti mahu mengalih keluar nombor ini?",
    confirmRemoveAllNumber:
      "Adakah anda pasti mahu mengalih keluar semua nombor?",
    confirmSubmitNumber: "Adakah anda pasti mahu membeli nombor ini?",
    basicLottery: "Loteri Asas",
    specialLottery: "Loteri Khas",
    gdLotto: "GD Lotto",
    chooseLotteryType: "Pilih Jenis Loteri",
    slip: "Slip",
    today: "Hari ini",
    yesterday: "Semalam",
    reorderSuccessfully: "Pembelian semula yang berjaya",
    voidSuccessfully: "Batal Berjaya",
    orderId: "ID Pesanan",
    reorder: "Pembelian Semula",
    void: "Batal",
    detail: "Perincian",
    basic: "Asas",
    advance: "Maju",
    totalWin: "Jumlah Kemenangan",
    totalBet: "Jumlah Pertaruhan",
    confirmVoidSlip: "Adakah anda pasti mahu membatalkan slip ini?",
    confirmReorder: "Adakah anda pasti mahu pembelian semula?",
    strikeBig: "Strike Besar",
    strikeSmall: "Strike Kecil",
    strike4a: "Strike 4A",
    estimatePrice: "Anggaran Harga",
    estimateTotal: "Anggaran Jumlah",
    season: "Musim",
    bet: "Pertaruhan",
    win: "Menang",
    loading: "Memuatkan",
    date: "Tarikh",
    cancel: "Batal",
    alert: "Amaran",
    purchaseSuccessfully: "Pembelian berjaya.",
    referenceId: "ID Rujukan",
    confirm: "Sahkan",
    success: "Kejayaan",
    chooseABank: "Pilih Bank",
    gateway: "Gerbang",
    paymentMethod: "Cara Pembayaran",
    paymentGateway: "Gerbang Pembayaran",
    atm: "ATM",
    telco: "Telco (Prabayar)",
    telconame: "Nama Telco",
    mobilename: "Nama Mudah Alih",
    chooseATelco: "Pilih A Telco",
    success: "Berjaya",
    fail: "Gagal",
    changePassword: "Tukar Kata Laluan",
    resetGamePassword: "Tetapkan Semula Kata Laluan Permainan",
    apkDownload: "Muat turun APK",
    createGameIdDescription:
      "Selamat datang! Untuk memulakan penciptaan ID permainan anda, hanya tekan butang di bawah. Langkah ini akan menjana pengecam unik anda, memberikan akses kepada dunia kemungkinan permainan. Bersedia untuk memulakan perjalanan permainan anda dengan hanya satu klik!",
    createGameId: "Cipta ID Permainan",
    receiptRequired: "Resit Diperlukan",
    receipt: "Resit",
    accountNumber: "Nombor Akaun",
    accountName: "Nama Akaun",
    bankName: "Nama Bank",
    depositAccount: "Akaun Deposit",
    selected: "Dipilih",
    depositAccountList: "Senarai Akaun Deposit",
    requestedDate: "Tarikh Diminta",
    bankname: "Nama Bank",
    gatewayname: "Nama Gerbang",
    lottery: "Lotteri",
    gameNotFound: "Permainan Tidak Ditemui",
    option: "Pilihan",
    changePosition: "Tukar Kedudukan",
    backPage: "Kembali Ke Halaman Sebelumnya",
    confirmation: "Pengesahan",
    confirmationSwitchRegion_1:
      "Dengan meneruskan penukaran mata wang, sila maklum bahawa baki semasa anda akan dikosongkan. Ini termasuk sebarang baki kredit, dana atau baki yang dikaitkan dengan akaun anda. Pastikan anda menyemak status akaun anda sebelum mengesahkan perubahan mata wang",
    confirmationSwitchRegion_2:
      "Adakah anda pasti mahu meneruskan menukar mata wang kepada {n} dan mengosongkan baki semasa anda? Jika sahkan isikan kata laluan anda dan tekan sahkan.",
    switchSuccessfully:
      "Berjaya menukar mata wang. Untuk menggunakan perubahan dan memastikan tetapan baharu berkuat kuasa, sila log masuk semula ke akaun anda",
    bankCard: "Kad Bank",
    addBank: "Tambah Bank",
    addBankDescription: "Sila ikat kad bank dahulu untuk pengeluaran",
    otherBankName: "Nama Bank Lain",
    otherBankNameRequired: "Nama Bank Lain Diperlukan",
    fullName: "Name Penuh",
    fullNameRequired: "Nama Penuh Diperlukan",
    accountNumRequired: "Nombor Akaun Diperlukan",
    rate: "Kadar",
    chat: "Sembang",
    depositRequestSendSuccessfully: "Permintaan Deposit Berjaya Dihantar",
    withdrawRequestSendSuccessfully: "Pengeluaran Permintaan Berjaya Dihantar",
    transferSuccessfully: "Pemindahan Berjaya",
    confirmRemoveBank: "Sahkan Padamkan Bank",
    addBankSuccessfully: "Tambah Bank Berjaya",
    deleteBankSuccessfully: "Padamkan Bank Berjaya",
    status: "Status",
    failToRegister: "Gagal Mendaftar",
    failToLogin: "Gagal Log Masuk",
    tnc: "Terma dan Syarat",
    userAgreement: "Perjanjian Pengguna ES51",
    userAgreementDscription_1:
      'Perjanjian ini (selepas ini dirujuk sebagai "Perjanjian") ialah kontrak antara anda dan ES51 mengenai penggunaan perkhidmatan yang berkaitan dengan platform ini. Sila baca dengan teliti dan bersetuju dengan semua syarat Perjanjian ini.',
    accountRegistration: "1. Pendaftaran akaun",
    accountRegistrationDscription_1:
      "Anda mesti berumur lebih daripada 18 tahun untuk menggunakan platform ini.",
    accountRegistrationDscription_2:
      "Semasa mendaftar akaun, anda perlu memberikan maklumat yang benar dan lengkap.",
    accountRegistrationDscription_3:
      "Anda perlu memastikan maklumat akaun anda selamat dan tidak berkongsi dengan orang lain.",
    accountRegistrationDscription_4:
      "Anda bertanggungjawab untuk semua aktiviti yang berlaku di bawah akaun anda.",
    depositsNWithdrawals: "2. Deposit dan Pengeluaran",
    depositsNWithdrawalsDscription_1:
      "Anda boleh membuat deposit dan pengeluaran melalui pelbagai kaedah yang disediakan oleh platform ini.",
    depositsNWithdrawalsDscription_2:
      "Kami mungkin mengenakan bayaran untuk kaedah deposit dan pengeluaran tertentu.",
    depositsNWithdrawalsDscription_3:
      "Anda perlu mematuhi peraturan deposit dan pengeluaran platform.",
    bettingRules: "3. Peraturan pertaruhan",
    bettingRulesDscription_1:
      "Anda boleh menggunakan dana akaun anda untuk meletakkan pertaruhan pada platform ini.",
    bettingRulesDscription_2:
      "Anda perlu memastikan akaun anda mempunyai dana yang mencukupi.",
    bettingRulesNoteTitle_1:
      "Kami berhak untuk membuat pertaruhan dengan dana yang melanggar keperluan Peraturan Pertaruhan Platform ini.",
    bettingRulesNoteDscription_1:
      "Gunakan strategi arbitraj atau tingkah laku lain yang melanggar prinsip permainan adil;",
    bettingRulesNoteDscription_2:
      "Kelakuan dengan kesilapan pertaruhan yang jelas atau niat penipuan;",
    bettingRulesNoteDscription_3:
      "Sebarang pelanggaran peraturan pertaruhan platform lain.",
    bettingRulesNoteTitle_2:
      "Kami berhak untuk mengharamkan akaun yang melanggar peraturan pertaruhan kami:",
    bettingRulesNoteDscription_4:
      "Akaun yang kerap membuat pertaruhan luar biasa atau pertaruhan kumpulan;",
    bettingRulesNoteDscription_5:
      "Akaun yang disyaki pengubahan wang haram, tunai keluar dan aktiviti haram dan jenayah lain;",
    bettingRulesNoteDscription_6:
      "Sebarang pelanggaran lain Perjanjian Pengguna Platform.",
    bonusesNPromotions: "4. Bonus dan Promosi",
    bonusesNPromotionsDscription_1:
      "Kami mungkin menawarkan bonus dan promosi.",
    bonusesNPromotionsDscription_2:
      "Setiap bonus dan promosi mungkin mempunyai terma dan syarat tertentu.",
    bonusesNPromotionsDscription_3:
      "Anda dikehendaki mematuhi terma dan syarat yang berkaitan untuk memanfaatkan bonus dan promosi.",
    playResponsibly: "5. Bermain Bertanggungjawab",
    playResponsiblyDscription_1:
      "Kami mempromosikan permainan yang bertanggungjawab.",
    playResponsiblyDscription_2:
      "Kami menyediakan had deposit, had masa dan alatan lain untuk membantu anda mengawal tingkah laku permainan anda.",
    playResponsiblyDscription_3:
      "Jika anda fikir anda mungkin mengalami masalah ketagihan permainan, sila dapatkan bantuan profesional dengan segera.",
    others: "6. Lain-lain",
    othersDscription_1:
      "Anda tidak boleh menggunakan Platform untuk sebarang tujuan yang menyalahi undang-undang atau penipuan.",
    othersDscription_2:
      "Kami berhak untuk mengubah suai Perjanjian ini pada bila-bila masa.",
    othersDscription_3:
      "Penggunaan berterusan anda terhadap platform ini merupakan persetujuan anda untuk menerima Perjanjian yang disemak.",
    othersDscription_4:
      "Hak tafsiran terakhir perjanjian ini adalah milik ES51",
    sportsbookTitle: "Sportsbook dalam talian yang paling terkenal di Asia",
    m9bet_description:
      "M9Bet Sports adalah permainan pertaruhan sukan yang dibentuk dan dibina oleh peneraju pasaran dalam sportsbook, M9Bet. M9Bet mempunyai pasukan-pasukan trader profesional dan wakil perkhidmatan pelanggan yang berdedikasi yang bekerja 24 jam sehari sepanjang tahun untuk menyediakan odds yang paling kompetitif dan pilihan sukan yang paling pelbagai kepada pemain di seluruh dunia",
    ibc_description:
      "IBCBet adalah permainan pertaruhan sukan yang dibentuk dan dibina oleh IBC sportsbook. IBC sportsbook menyediakan lebih daripada seribu perlawanan langsung, seperti soccer, bola keranjang, tenis, bola tampar dan lain-lain, menampilkan penyesuaian terpantas dan sokongan teknikal yang paling komprehensif kepada para pemain.",
    ebet_description:
      "World Entertainment adalah permainan pertaruhan sukan yang dibentuk dan dibina oleh WE sportsbook. WE sportsbook menyediakan perkhidmatan pertaruhan untuk acara sukan berdasarkan dinamik global masa nyata dan topik hangat pasaran, ia termasuk tetapi tidak terhad kepada bola keranjang, bola sepak, eSports, dan badminton.",
    downloadapp: "Download App",
    gmail: "Gmail",
    invalidEmailFormat: "Format E-mel Tidak Sah",
    emailRequired: "E-mel Diperlukan",
    resetSuccess: "Tetapkan Semula Kejayaan",
    registerSuccess1:
      "Terima kasih kerana memilih kami, Kami telah menghantar nama pengguna dan kata laluan ke Gmail berdaftar: ",
    registerSuccess2:
      " Untuk keselamatan akaun anda. Kami harap anda boleh menukar kata laluan anda tidak lama lagi.",
    tranferBonusDescription: "Adakah anda pasti mahu klaim bonus?",
    changePasswordSuccess: "Kata laluan berjaya dikemas kini.",
    invalidConfirmPassword: "Kata laluan pengesahan tidak sah",
    gameIdCreateSuccessfully: "Berjaya dicipta.",
    incorrectVerificationCode: "Kod pengesahan yang salah",
    backErrorDescription:
      "Sila ambil perhatian bahawa jika anda memutuskan untuk kembali, anda akan diminta untuk mengulangi semua langkah sekali lagi. Adakah anda pasti mahu meneruskannya?",
    phoneAuth: "Pengesahan Telefon",
    respondDate: "Tarikh Balas",
    confirmationCancelDeposit:
      "Adakah anda pasti mahu membatalkan permintaan deposit?",
    depositRequestCancelSuccessfully: "Permintaan deposit berjaya dibatalkan.",
    prove: "Buktikan",
    rejectReason: "Tolak Alasan : ",
    confirmationCancelWithdraw:
      "Adakah anda pasti mahu membatalkan permintaan pengeluaran?",
    withdrawRequestCancelSuccessfully: "Pengeluaran permintaan batal berjaya.",
    openNewTab: "Ubah hala Ke Perbankan Dalam Talian",
    openNewTab1:
      "Menggunakan ciri ini tidak akan menyediakan perkara berikut : ",
    openNewTab2: "Bonus topup harian RM5",
    openNewTab3: "Beli loteri",
    openNewRedirectTab: "Ubah hala Ke Halaman Ejen",
    comingSoon: "Akan Datang!",
    team: "Pasukan",
    joindate: "Tarikh menyertai:",
    noDownline: "Downline Tidak Ditemui",
    lastwithdrawrejected: "Permintaan Terakhir Ditolak",
    redirecttoagent: "Ubah hala kepada ejen",
    contactUs: "Hubungi Kami",
    noPaymentMethodFound: "Tiada Kaedah Pembayaran Ditemui",
    chooseLoginAccount: "Pilih Akaun Log Masuk",
    addAcc: "Tambah Akaun",
    addAccSuccessfully: "Tambahkan Akun Berjaya",
  },
  [LOCALES.INDO]: {
    sessionLost: "Sesi Hilang. Silakan masuk kembali",
    errorOccurContactAdmin:
      "Terjadi kesalahan. Silakan hubungi administrator untuk membantu.",
    marquee_home: "Halo Ini Dari kiri ke kanan",
    welcome: "Selamat datang,",
    cash: "Uang Tunai",
    bonus: "Bonus",
    point: "Point",
    deposit: "Deposit",
    transfer: "Pindah",
    withdraw: "Penarikan",
    load_more: "Muat lebih banyak",
    game: "Permainan",
    wallet: "Dompet",
    promotion: "Promosi",
    result: "Hasil",
    profile: "Profil",
    gameid: "ID Permainan",
    language: "Bahasa",
    reset_password: "Atur Ulang Kata Sandi",
    member_transfer: "Perpindahan Anggota",
    invite_friend: "Mengundang Teman",
    choose_language: "Pilih Bahasa",
    close: "Tutup",
    transfer_amount: "Jumlah transfer",
    main_wallet: "Dompet Utama",
    hide_wallet: "Sembunyikan Dompet",
    lock_wallet: "Kunci Dompet",
    qr: "QR",
    bank: "Bank",
    paying_method: "Metode Pembayaran",
    deposit_amount: "Jumlah Deposit",
    target: "Target",
    copy: "Salin",
    address: "Alamat",
    top_up: "Isi Ulang",
    balance: "Saldo",
    my_balance: "Saldo Anda",
    welcomeBack: "Selamat Datang Kembali!",
    memberId: "ID Anggota",
    username: "Nama Pengguna",
    password: "Kata Sandi",
    forgetpassword: "Lupa Kata Sandi",
    login: "Gabung",
    or: "Atau",
    loginWithGoogle: "Gabung Dengan Google",
    dontHaveAccount: "Tidak ada akun ?",
    signUp: "Daftar",
    chooseRegisterMethod: "Pilih Metode Pendaftaran",
    registerWithGoogle: "Daftar dengan Google",
    orRegisterWith: "Atau Daftar Dengan",
    registerWithPhone: "Daftar Dengan Telepon",
    alreadyHaveAccount: "Sudah Punya Akun?",
    phoneNo: "No.Telepon",
    otp: "OTP",
    refferalCode: "Kode Referensi",
    verificationCode: "Kode Verifikasi",
    confirmPassword: "Konfirmasikan Kata Sandi",
    register: "Daftar",
    next: "Berikutnya",
    usernameRequired: "Nama Pengguna Diperlukan",
    passwordRequired: "Kata Sandi Diperlukan",
    forgetpassword: "Lupa Kata Sandi",
    referralCodeRequired: "Kode Referensi Diperlukan",
    verifyCodeRequired: "Kode Verifikasi Diperlukan",
    requestOTP: "Minta OTP",
    phoneNoRequired: "No.Telepon Diperlukan",
    otpHasSended:
      "Terima kasih telah memberikan nomor telepon Anda. Kami baru saja mengirimkan one-time password (OTP) ke nomor tersebut",
    otpRequired: "OTP Diperlukan",
    currency: "Mata uang",
    currencyRequired: "Mata uang Diperlukan",
    error: "Kesalahan",
    home: " Beranda",
    report: "Laporan",
    title: "Judul",
    search: "Cari",
    noRecordFound: "Catatan Tidak Ditemukan",
    personal: "Pribadi",
    pointshop: "Toko Point",
    exchange: "Menukarkan",
    inbox: "Kotak Masuk",
    contact_us: "Hubungi kami",
    logout: "Keluar",
    history: "Sejarah",
    play: "Main",
    playNow: "Main Sekarang",
    promotion: "Promosi",
    detail: "Detil",
    copySuccessfully: "Salin Berhasil",
    mandarin: "Bahasa Cina",
    english: "Bahasa inggris",
    malay: "Bahasa Melayu",
    indo: "Bahasa Indo",
    registerDate: "Tanggal Pendaftaran",
    submit: "Kirim",
    newPassword: "Kata Sandi Baru",
    oldPassword: "Kata Sandi Lama",
    editPassword: "Sunting Kata Sandi",
    oldPasswordRequired: "Kata Sandi Lama Diperlukan",
    newPasswordRequired: "Kata Sandi Baru Diperlukan",
    confirmPasswordRequired: "Konfirmasi Kata Sandi Diperlukan",
    transactionReport: "Laporan Transaksi",
    gameReport: "Laporan Permainan",
    pointShop: "Toko Point",
    unlimitedClaim: "Klaim Tanpa Batas",
    duration: "Durasi",
    limitedClaim: "Klaim Terbatas",
    available: "Tersedia",
    claimBonus: "Klaim Bonus",
    atLeast1toClaim: "Minimal 1 untuk Klaim",
    all: "Semua",
    amount: "Jumlah",
    receiverUsername: "Nama Pengguna Penerima",
    receiverUsernameRequired: "Nama Pengguna Penerima Diperlukan",
    amountRequired: "Jumlah Diperlukan",
    memberTransferDscription_1:
      "Harap pastikan detail yang akurat saat mentransfer saldo ke pengguna lain. Periksa kembali informasi penerima, untuk mencegah kesalahan transfer. Ketekunan Anda memastikan pengalaman transaksi yang lancar dan aman bagi Anda dan penerima.",
    memberTransferDscription_2:
      "Terima kasih atas perhatian anda terhadap detail!",
    attention: "Perhatian",
    memberTransfer: "Perpindahan Anggota",
    noWithdrawalAccount:
      "Untuk memastikan kelancaran transaksi dan penarikan yang aman, kami dengan hormat meminta anda untuk menambahkan akun penarikan di profil anda. Langkah ini penting untuk memproses penarikan anda secara efisien dan menjaga dana anda.",
    withdrawalDescriptionTitle: "Cara kerja penarikan :",
    withdrawalDescriptionTitle_1: "Pilih Akun Penarikan Anda",
    withdrawalDescription_1:
      "Pilih akun penarikan pilihan dari opsi yang tersedia di daftar akun penarikan anda.",
    withdrawalDescriptionTitle_2: "Masukkan Detail Penarikan",
    withdrawalDescription_2: "Masukkan jumlah yang ingin anda tarik",
    withdrawalDescriptionTitle_3: "Konfirmasi dan Proses",
    withdrawalDescription_3:
      "Periksa kembali detail anda dan tekan 'Konfirmasi' untuk memulai proses penarikan.",
    withdrawalNoteTitle: "Tolong catat",
    withdrawalNoteDescription_1:
      "Penarikan mungkin memerlukan waktu pemrosesan tertentu tergantung pada metode yang anda pilih dan lembaga keuangan yang terlibat.",
    withdrawalNoteDescription_2:
      "Pastikan rincian penarikan anda akurat untuk mencegah keterlambatan dalam menerima dana anda.",
    withdrawalNoteDescription_3:
      "Jika anda mengalami masalah atau memiliki pertanyaan, tim dukungan kami siap membantu Anda.",
    withdrawalNoteDescription_4:
      "Terima kasih telah memilih kami untuk transaksi keuangan anda. Kami berusaha membuat pengalaman penarikan anda semulus mungkin!",
    goToProfile: "Arahkan ulang ke menambahkan bank",
    pending: "Tertunda",
    withdrawalAccountList: "Lis Rekening Penarikan",
    gameTransfer: "Pemindahan Permainan",
    clickTransfer: "Klik Pindahkan",
    transferAllToWallet: "Transfer Semua Ke Dompet",
    walletBalance: "Saldo Dompet",
    canceled: "Dibatal",
    reject: "Tolak",
    approve: "Lulus",
    onProgress: "Dalam Perkembangannya",
    request: "Permintaan",
    days: "Hari",
    companyList: "Lis Perusahaan",
    viewResult: "Lihat Hasil",
    betNow: "Bertaruh Sekarang",
    payout: "Pembayaran",
    report: "Laporan",
    prize: "Hadiah",
    special: "Spesial",
    consolation: "Penghiburan",
    refreshIn: "Segarkan",
    chooseABank: "Pilih bank",
    chooseSeason: "Pilih Musim",
    chooseCompany: "Pilih Perusahaan",
    number: "Nomor",
    numberList: "Lis Nomor",
    big: "Besar",
    small: "Kecil",
    "4a": "4A",
    betType: "Jenis Taruhan",
    add: "Tambah",
    numberRequired: "Nomor Diperlukan",
    sumMin1: "Jumlah Besar, Kecil, 4A minimal harus 1",
    numberMin4: "Nomor harus 4 digit",
    companyRequired: "Perusahaan diperlukan",
    total: "Jumlah",
    company: "Perusahaan",
    confirmRemoveNumber: "Apakah anda yakin ingin menghapus nomor ini?",
    confirmRemoveAllNumber:
      "Apakah anda yakin ingin menghapus semua nomor tersebut?",
    confirmSubmitNumber: "Apakah anda yakin ingin membeli nomor ini?",
    basicLottery: "Togel Dasar",
    specialLottery: "Togel Khusus",
    gdLotto: "GD Lotto",
    chooseLotteryType: "Pilih Jenis Togel",
    slip: "Slip",
    today: "Hari Ini",
    yesterday: "Kemarin",
    reorderSuccessfully: "Memesan Ulang Berhasil",
    voidSuccessfully: "Batal Berhasil",
    orderId: "ID Pemesanan",
    reorder: "Memesan Ulang",
    void: "Batal",
    detail: "Perincian",
    basic: "Dasar",
    advance: "Maju",
    totalWin: "Jumlah Kemenangan",
    totalBet: "Jumlah Taruhan",
    confirmVoidSlip: "Apakah anda yakin ingin membatalkan slip ini?",
    confirmReorder: "Apakah anda yakin ingin memesan ulang?",
    strikeBig: "Strike Besar",
    strikeSmall: "Strike Kecil",
    strike4a: "Strike 4A",
    estimatePrice: "Perkiraan Harga",
    estimateTotal: "Perkiraan Total",
    season: "Musim",
    bet: "Bertaruh",
    win: "Menang",
    loading: "Memuat",
    date: "Tanggal",
    cancel: "Batal",
    alert: "Peringatan",
    purchaseSuccessfully: "Pembelian berhasil.",
    referenceId: "ID Referensi",
    confirm: "Konfirmasi",
    success: "Sukses",
    chooseABank: "Pilih Bank",
    gateway: "Gerbang",
    paymentMethod: "Cara Pembayaran",
    paymentGateway: "Gerbang Pembayaran",
    atm: "ATM",
    telco: "Telco (Prabayar)",
    telconame: "Nama Telco",
    mobilename: "Nama mobil",
    chooseATelco: "Pilih Perusahaan Telco",
    success: "Sukses",
    fail: "Gagal",
    changePassword: "Ganti Kata Sandi",
    resetGamePassword: "Setel Ulang Kata Sandi Permainan",
    apkDownload: "Unduhan APK",
    createGameIdDescription:
      "Selamat datang! Untuk memulai pembuatan ID game anda, cukup tekan tombol di bawah. Langkah ini akan menghasilkan pengenal unik anda, memberikan akses ke dunia kemungkinan permainan. Bersiaplah untuk memulai perjalanan bermain game anda hanya dengan satu klik!",
    createGameId: "Buat ID Permainan",
    receiptRequired: "Resi Diperlukan",
    receipt: "Resi",
    accountNumber: "Nomor Akun",
    accountName: "Nama Akun",
    bankName: "Nama Bank",
    depositAccount: "Akun Deposit",
    selected: "Terpilih",
    depositAccountList: "Lis Akun Deposit",
    requestedDate: "Tanggal yang diminta",
    bankname: "Nama Bank",
    gatewayname: "Nama Gerbang",
    lottery: "Togel",
    gameNotFound: "Permainan Tidak Ditemukan",
    option: "Opsi",
    changePosition: "Ubah Posisi",
    backPage: "Kembali ke halaman sebelumnya",
    confirmation: "Konfirmasi",
    confirmationSwitchRegion_1:
      "Dengan melanjutkan peralihan mata uang, perlu diketahui bahwa saldo anda saat ini akan dihapus. Ini termasuk sisa kredit, dana, atau saldo yang terkait dengan akun anda. Pastikan untuk meninjau status akun anda sebelum mengonfirmasi perubahan mata uang",
    confirmationSwitchRegion_2:
      "Apakah Anda yakin ingin melanjutkan untuk mengganti mata uang ke {n} dan menghapus saldo Anda saat ini? Jika konfirmasi, isi kata sandi Anda dan tekan konfirmasi.",
    switchSuccessfully:
      "Beralih mata uang berhasil. Untuk menerapkan perubahan dan memastikan pengaturan baru diterapkan, silakan masuk kembali ke akun anda",
    bankCard: "Kartu Bank",
    addBank: "Tambah Bank",
    addBankDescription:
      "Silakan ikat kartu bank terlebih dahulu untuk penarikan",
    otherBankName: "Nama Bank Lainnya",
    otherBankNameRequired: "Nama Bank Lain Diperlukan",
    fullName: "Nama Lengkap",
    fullNameRequired: "Nama Lengkap Diperlukan",
    accountNumRequired: "Nomor Akun Diperlukan",
    rate: "Kecepatan",
    chat: "Obrolan",
    depositRequestSendSuccessfully: "Permintaan Deposit Berhasil Dikirim",
    withdrawRequestSendSuccessfully:
      "Permintaan Menarik Diri Berhasil Terkirim",
    transferSuccessfully: "Pemindahan Berhasil",
    confirmRemoveBank: "Konfirmasi Hapus Bank",
    addBankSuccessfully: "Tambahkan Bank Berhasil",
    deleteBankSuccessfully: "Hapus Bank Berhasil",
    status: "Keadaan",
    failToRegister: "Gagal Mendaftar",
    failToLogin: "Gagal Masuk",
    tnc: "Syarat dan Ketentuan",
    userAgreement: "Perjanjian Pengguna ES51",
    userAgreementDscription_1:
      'Perjanjian ini (selanjutnya disebut "Perjanjian") adalah kontrak antara Anda dan ES51 mengenai penggunaan layanan yang terkait dengan platform ini. Harap membaca dengan cermat dan menyetujui semua ketentuan Perjanjian ini.',
    accountRegistration: "1. Pendaftaran akun",
    accountRegistrationDscription_1:
      "Anda harus berusia di atas 18 tahun untuk menggunakan platform ini.",
    accountRegistrationDscription_2:
      "Saat mendaftar akun, Anda harus memberikan informasi yang benar dan lengkap.",
    accountRegistrationDscription_3:
      "Anda harus menjaga informasi akun Anda tetap aman dan tidak membaginya dengan orang lain.",
    accountRegistrationDscription_4:
      "Anda bertanggung jawab atas semua aktivitas yang terjadi di bawah akun Anda.",
    depositsNWithdrawals: "2. Setoran dan Penarikan",
    depositsNWithdrawalsDscription_1:
      "Anda dapat melakukan deposit dan penarikan melalui berbagai metode yang disediakan oleh platform ini.",
    depositsNWithdrawalsDscription_2:
      "Kami mungkin mengenakan biaya untuk metode deposit dan penarikan tertentu.",
    depositsNWithdrawalsDscription_3:
      "Anda harus mematuhi aturan penyetoran dan penarikan platform.",
    bettingRules: "3. Aturan taruhan",
    bettingRulesDscription_1:
      "Anda dapat menggunakan dana akun Anda untuk memasang taruhan di platform ini.",
    bettingRulesDscription_2:
      "Anda perlu memastikan bahwa akun Anda memiliki dana yang cukup.",
    bettingRulesNoteTitle_1:
      "Kami berhak membuat taruhan dengan dana yang melanggar persyaratan Peraturan Taruhan Platform ini.",
    bettingRulesNoteDscription_1:
      "Menggunakan strategi arbitrase atau perilaku lain yang melanggar prinsip fair play;",
    bettingRulesNoteDscription_2:
      "Melakukan kesalahan taruhan yang nyata atau niat curang;",
    bettingRulesNoteDscription_3:
      "Pelanggaran terhadap aturan taruhan platform lain.",
    bettingRulesNoteTitle_2:
      "Kami berhak untuk melarang akun yang melanggar aturan taruhan kami:",
    bettingRulesNoteDscription_4:
      "Akun yang secara rutin membuat taruhan tidak biasa atau taruhan grup;",
    bettingRulesNoteDscription_5:
      "Rekening yang dicurigai melakukan pencucian uang, penarikan tunai, dan kegiatan ilegal dan kriminal lainnya;",
    bettingRulesNoteDscription_6:
      "Pelanggaran lainnya terhadap Perjanjian Pengguna Platform.",
    bonusesNPromotions: "4. Bonus dan Promosi",
    bonusesNPromotionsDscription_1:
      "Kami mungkin menawarkan bonus dan promosi.",
    bonusesNPromotionsDscription_2:
      "Setiap bonus dan promosi mungkin memiliki syarat dan ketentuan tertentu",
    bonusesNPromotionsDscription_3:
      "Anda diharuskan mematuhi syarat dan ketentuan yang relevan untuk memanfaatkan bonus dan promosi.",
    playResponsibly: "5. Bermain Bertanggung Jawab",
    playResponsiblyDscription_1:
      "Kami mempromosikan permainan yang bertanggung jawab.",
    playResponsiblyDscription_2:
      "Kami memberikan batas setoran, batas waktu, dan alat lain untuk membantu Anda mengontrol perilaku bermain game Anda.",
    playResponsiblyDscription_3:
      "Jika Anda merasa menderita kecanduan game, harap segera mencari bantuan profesional.",
    others: "6. Lainnya",
    othersDscription_1:
      "Anda tidak boleh menggunakan Platform untuk tujuan ilegal atau penipuan apa pun.",
    othersDscription_2: "Kami berhak mengubah Perjanjian ini kapan saja.",
    othersDscription_3:
      "Penggunaan berkelanjutan Anda atas platform ini merupakan persetujuan Anda untuk menerima Perjanjian yang direvisi.",
    othersDscription_4:
      "Hak penafsiran akhir atas perjanjian ini adalah milik ES51",
    sportsbookTitle: "Sportsbook online paling terkemuka di Asia",
    m9bet_description:
      "M9Bet Sports adalah permainan taruhan olahraga yang disediakan oleh sportsbook terkemuka di pasar, M9Bet. Sportsbook online terkenal ini memiliki tim berdedikasi yang terdiri dari ratusan pedagang profesional dan perwakilan layanan pelanggan yang bekerja 24 jam sehari sepanjang tahun untuk memberikan peluang kompetitif dan variasi olahraga serta aktivitas terluas bagi para pemain di seluruh dunia.",
    ibc_description:
      "IBCBet adalah permainan taruhan olahraga yang disediakan oleh IBC sportsbook. Sportsbook IBC menawarkan ribuan permainan langsung, seperti sepak bola, bola basket, tenis, bola voli, dan banyak lagi, menampilkan penyesuaian tercepat dan dukungan teknis terlengkap.",
    ebet_description:
      "World Entertainment adalah permainan taruhan olahraga yang disediakan oleh WE sportsbook. WE Sportsbook menawarkan layanan taruhan untuk acara olahraga berdasarkan dinamika global real-time dan topik hangat pasar, termasuk namun tidak terbatas pada bola basket, sepak bola, eSports, golf, dan bulu tangkis.",
    downloadapp: "Unduhan App",
    gmail: "Gmail",
    invalidEmailFormat: "Format Email Salah",
    emailRequired: "Email Diperlukan",
    resetSuccess: "Atur Ulang Sukses",
    registerSuccess1:
      "Terima kasih telah memilih kami, Kami telah mengirimkan username dan password ke Gmail yang terdaftar: ",
    registerSuccess2:
      " Demi keamanan akun Anda. Kami harap Anda dapat segera mengubah kata sandi Anda.",
    tranferBonusDescription: "Apakah anda yakin ingin mengklaim bonusnya?",
    changePasswordSuccess: "Perubahan kata sandi berhasil.",
    invalidConfirmPassword: "Konfirmasi kata sandi tidak valid",
    gameIdCreateSuccessfully: "Berhasil dibuat.",
    incorrectVerificationCode: "Kode verifikasi salah",
    backErrorDescription:
      "Harap dicatat bahwa jika Anda memutuskan untuk kembali, anda akan diminta untuk mengulangi semua langkah lagi. Apakah anda yakin ingin melanjutkan?",
    phoneAuth: "Verifikasi Telepon",
    respondDate: "Tanggal Tanggapan",
    confirmationCancelDeposit:
      "Apakah anda yakin ingin membatalkan permintaan deposit?",
    depositRequestCancelSuccessfully: "Permintaan deposit berhasil dibatalkan.",
    prove: "Buktikan",
    rejectReason: "Alasan Ditolak : ",
    confirmationCancelWithdraw:
      "Apakah anda yakin ingin membatalkan permintaan penarikan?",
    withdrawRequestCancelSuccessfully:
      "Permintaan penarikan dibatalkan berhasil.",
    openNewTab: "Pengalihan Ke Perbankan Online",
    openNewTab1: "Menggunakan fitur ini tidak akan memberikan hal berikut : ",
    openNewTab2: "Bonus isi ulang harian RM5",
    openNewTab3: "Beli lotere",
    openNewRedirectTab: "Redirect Ke Halaman Agen",
    comingSoon: "Segera Hadir!",
    team: "Tim",
    joindate: "Tanggal Bergabung:",
    noDownline: "Downline Tidak Ditemukan",
    lastwithdrawrejected: "Permintaan Terakhir Ditolak",
    redirecttoagent: "Alihkan ke agen",
    contactUs: "Hubungi Kami",
    noPaymentMethodFound: "Tidak Ditemukan Metode Pembayaran",
    chooseLoginAccount: "Pilih Akun Masuk",
    addAcc: "Tambahkan Akun",
    addAccSuccessfully: "Tambahkan Akun Berhasil",
  },
};
