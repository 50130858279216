import { Button, Dialog, Drawer } from "@mui/material";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import QRCode from "react-qr-code";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { CopyToClipboard, toAbsoluteUrl } from "../../../utils/CommonHelper";
import { useAuth } from "../../auth/components/AuthInit";
import * as actions from "../redux/SettingAction";
import { EditPasswordDialog } from "./personal/editPassword/EditPasswordDialog";

export const Setting = ({}) => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const intl = useIntl();
  const [openLanguage, setOpenLanguage] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const settingState = useSelector(({ setting }) => setting, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [redirectAgent, setRedirectAgent] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handlNew = () => {
    window.open(redirectAgent);
    setOpen(false);
  };
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const _showLanguage = () => {
    setOpenLanguage(true);
  };

  const handleCloseLanguage = () => {
    setOpenLanguage(false);
  };

  const handleLogout = async () => {
    if (
      sessionStorage.getItem("platform") === "mobile" ||
      sessionStorage.getItem("platform") === "telegram"
    ) {
      console.log("press:logout");
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key !== "platform" && key !== "telegramID") {
          sessionStorage.removeItem(key);
          i--;
        }
      }
      localStorage.clear();
      window.location.reload();
    } else {
      await logout();
    }
  };

  const handleDepositClick = () => {
    navigate("/setting/deposit", { state: { backTo: "/setting/profile" } });
  };
  const handleWithdrawClick = () => {
    navigate("/setting/withdraw", { state: { backTo: "/setting/profile" } });
  };
  const handleTransferClick = () => {
    navigate("/setting/transfer", { state: { backTo: "/setting/profile" } });
  };
  const handleMemberTransferClick = () => {
    navigate("/setting/memberTransfer");
  };
  const encryptData = (originalData) => {
    return CryptoJS.AES.encrypt(originalData, "es51#!@es51").toString();
  };
  const handleRedirectToAgent = () => {
    dispatch(actions.getAgentUsename()).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setRedirectAgent(
          `https://agent.es51.com/?credential=${encodeURIComponent(
            btoa(encryptData(JSON.stringify(resp)))
          )}`
        );
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log(
            `openurl: https://agent.es51.com/?credential=${encodeURIComponent(
              btoa(encryptData(JSON.stringify(resp)))
            )}`
          );
        } else {
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari || sessionStorage.getItem("platform") === "telegram") {
            setOpen(true);
          } else {
            window.open(
              `https://agent.es51.com/?credential=${encodeURIComponent(
                btoa(encryptData(JSON.stringify(resp)))
              )}`
            );
          }
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const handlePersonalClick = () => {
    navigate("/setting/personal");
  };
  const handlePointShopClick = () => {
    toggleDialog();
    // navigate("/setting/pointShop");
  };
  const handleBonusClick = () => {
    navigate("/setting/bonus");
  };
  const handleReportClick = () => {
    navigate("/setting/report", { state: { backTo: "/setting/profile" } });
  };
  const handleTeamClick = () => {
    navigate("/setting/team");
  };
  const handleContactUsClick = () => {
    navigate("/setting/contactUs");
  };

  const handleLanguageChange = (language) => {
    console.log("update:language");
    localStorage.setItem("locale", language);
    window.location.reload();
    handleCloseLanguage();
  };

  const handleAddBankClick = () => {
    navigate("/setting/addbank");
  };

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  return (
    <div>
      <EditPasswordDialog setSwalProps={setSwalProps} />
      <div style={styles.navBar}>
        <div style={styles.backButton}>
          <Link to="/home">
            <FiArrowLeft style={{ color: "white" }} />
          </Link>
        </div>
        <div style={styles.title} className="text-font4">
          {intl.formatMessage({ id: "profile" })}
        </div>
      </div>
      <div style={{ height: "10px" }}></div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {auth ? (
          <div className="mb-3" style={{ marginTop: "10px" }}>
            <QRCode
              value={auth.memberid}
              size={150}
              bgColor="#1c1e23"
              fgColor="#fff"
            />
          </div>
        ) : (
          <Skeleton className="w-100" height="50px" />
        )}
      </div>
      <div className="d-flex flex-column align-items-center pb-2">
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          {auth && (
            <>
              <div className="text-font2">{auth.memberid}</div>
              <i
                className="ms-2 fa fa-copy cursor-pointer"
                style={{ color: "white" }}
                onClick={() => {
                  if (sessionStorage.getItem("platform") === "mobile") {
                    console.log(`clipboard: ${auth.memberid}`);
                    CopyToClipboard(auth.memberid, setSwalProps, intl);
                  } else {
                    CopyToClipboard(auth.memberid, setSwalProps, intl);
                  }
                }}
              />
            </>
          )}
        </div>
        <div style={{ border: "1px solid #2e323a", width: "70%" }}></div>
        {auth && auth.memberid && (
          <div
            className="d-flex align-items-center"
            style={{ width: "60%", display: "flex", justifyContent: "center" }}
          >
            <marquee
              behavior="scroll"
              direction="right"
              style={{ color: "white" }}
            >
              <div className="pe-3 text-font2">
                https://m.es51.com/register/{auth.memberid}
              </div>
            </marquee>
            <i
              className="ms-2 fa fa-copy cursor-pointer"
              style={{ color: "white" }}
              onClick={() => {
                if (sessionStorage.getItem("platform") === "mobile") {
                  console.log(
                    `clipboard: https://m.es51.com/register/${auth.memberid}`
                  );
                  CopyToClipboard(
                    `https://m.es51.com/register/${auth.memberid}`,
                    setSwalProps,
                    intl
                  );
                } else {
                  CopyToClipboard(
                    `https://m.es51.com/register/${auth.memberid}`,
                    setSwalProps,
                    intl
                  );
                }
              }}
            />
          </div>
        )}
      </div>
      <div style={{ height: "10px" }}></div>
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "0 auto",
          padding: "5px",
          display: "flex",
          borderRadius: "5px",
        }}
      >
        <div
          onClick={handleDepositClick}
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto auto",
            color: "white",
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={toAbsoluteUrl("image/deposit2.png")}
              alt="Deposit"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ fontSize: "10px" }}>
            {intl.formatMessage({ id: "deposit" })}
          </div>
        </div>

        <div
          onClick={handleTransferClick}
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto auto",
            color: "white",
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={toAbsoluteUrl("image/transfer2.png")}
              alt="Transfer"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ fontSize: "10px" }}>
            {intl.formatMessage({ id: "transfer" })}
          </div>
        </div>
        <div
          onClick={handleWithdrawClick}
          style={{
            height: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto auto",
            color: "white",
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={toAbsoluteUrl("image/withdraw2.png")}
              alt="Withdraw"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ fontSize: "10px" }}>
            {intl.formatMessage({ id: "withdraw" })}
          </div>
        </div>
      </div>

      <div style={{ height: "10px" }}></div>
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "0 auto",
          paddingTop: "5px",
          borderRadius: "5px",
        }}
      >
        {auth.bindagent === "true" && (
          <div
            onClick={handleRedirectToAgent}
            style={{
              height: "40px",
              width: "95%",
              display: "flex",
              alignItems: "center",
              margin: "0 auto",
              padding: "5px",
              color: "white",
            }}
          >
            <div style={{ flex: 2 }}>
              <img
                src={toAbsoluteUrl("/image/redirectagent.png")}
                alt="Redirect "
                style={{ width: "25px", height: "25px" }}
              />
            </div>
            <div style={{ flex: 7, fontSize: "10px" }}>
              {intl.formatMessage({ id: "redirecttoagent" })}
            </div>
            <div style={{ flex: 1 }}>
              <FiChevronRight />
            </div>
          </div>
        )}
        {/* <div
          onClick={handlePersonalClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/information.png")}
              alt="Personal"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "personal" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div> */}
        {/* <div
          onClick={openDialog}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/terms.png")}
              alt="tnc"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "tnc" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div> */}
        <div
          onClick={handleMemberTransferClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("image/membertransfer.png")}
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "member_transfer" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div>
        <div
          onClick={handleAddBankClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/bank.png")}
              alt="Add Bank"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "bankCard" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div>

        <div
          data-bs-toggle="modal"
          data-bs-target="#passwordModal"
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/resetpassword.png")}
              alt="Add Bank"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "reset_password" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div>
        {/* <div
          onClick={handleReportClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/report.png")}
              alt="Report"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "report" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div> */}
        {/* <div
          onClick={handleTeamClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/team.png")}
              alt="Report"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "team" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div>
        <div
          onClick={handlePointShopClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "auto auto",
            padding: "5px",
            color: "white",
          }}
        >
          {showDialog && (
            <div className="dialogComing">
              <div className="dialogComing-content">
                <span className="text-font3" style={{ padding: "30px" }}>
                  {intl.formatMessage({ id: "comingSoon" })}
                </span>
                <div
                  className="text-font3 mt-3"
                  style={{ textAlign: "end", color: "#f5be09" }}
                  onClick={toggleDialog}
                >
                  {intl.formatMessage({ id: "close" })}
                </div>
              </div>
            </div>
          )}
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/pointshop.png")}
              alt="Point Shop"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "pointshop" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div> */}
        {/* <div
          onClick={handleBonusClick}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/bonus.png")}
              alt="Bonus"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "bonus" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div> */}
      </div>
      <div style={{ height: "10px" }}></div>
      <div
        onClick={handleContactUsClick}
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "0 auto",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          <div style={{ flex: 2 }}>
            <img
              src={toAbsoluteUrl("/image/contactus.png")}
              alt="Contact Us"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
          <div style={{ flex: 7, fontSize: "10px" }}>
            {intl.formatMessage({ id: "contact_us" })}
          </div>
          <div style={{ flex: 1 }}>
            <FiChevronRight />
          </div>
        </div>
      </div>

      <div style={{ height: "10px" }}></div>
      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "0 auto",
          paddingTop: "5px",
          borderRadius: "5px",
        }}
      >
        <div
          onClick={_showLanguage}
          style={{
            height: "40px",
            width: "95%",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "5px",
            color: "white",
          }}
        >
          {auth && auth.memberid && (
            <>
              <div style={{ flex: 2 }}>
                {auth.language === "id" ? (
                  <img
                    src={toAbsoluteUrl("image/id.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="Malay"
                  />
                ) : auth.language === "zh" ? (
                  <img
                    src={toAbsoluteUrl("image/cn.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="Mandarin"
                  />
                ) : auth.language === "ms" ? (
                  <img
                    src={toAbsoluteUrl("image/my.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="Mandarin"
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl("image/en.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="English"
                  />
                )}
              </div>

              <div style={{ flex: 7, fontSize: "10px" }}>
                {auth.language === "id"
                  ? intl.formatMessage({ id: "indo" })
                  : auth.language === "zh"
                  ? intl.formatMessage({ id: "mandarin" })
                  : auth.language === "ms"
                  ? intl.formatMessage({ id: "malay" })
                  : intl.formatMessage({ id: "english" })}
              </div>
              <div style={{ flex: 1 }}>
                <FiChevronRight />
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div
        className="text-font3"
        style={{ width: "95%", margin: "0 auto", paddingTop: "5px" }}
      >
        <button
          style={{
            backgroundColor: "#FF0000",
            color: "white",
            borderRadius: "5px",
            width: "100%",
            padding: "5px",
          }}
          onClick={handleLogout}
        >
          {intl.formatMessage({ id: "logout" })}
        </button>
      </div>
      <div style={{ height: "20px" }}></div>
      <Drawer
        anchor="bottom"
        open={openLanguage}
        onClose={handleCloseLanguage}
        style={{ minHeight: "40vh" }}
      >
        <div style={{ backgroundColor: "#1c1e23", padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleCloseLanguage}>
              <FiArrowLeft style={{ color: "white" }} />
            </Button>
            <div style={{ flex: 7, color: "white" }} className="text-font4">
              {intl.formatMessage({ id: "choose_language" })}
            </div>
            <div style={{ flex: 2 }}></div>
          </div>

          <div
            style={{
              color: "white",
              cursor: "pointer",
              width: "100%",
              paddingTop: "20px",
            }}
            className="text-font3"
          >
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("zh")}
            >
              <img
                src={toAbsoluteUrl("/image/cn.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "mandarin" })}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("en")}
            >
              <img
                src={toAbsoluteUrl("/image/en.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "english" })}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("ms")}
            >
              <img
                src={toAbsoluteUrl("/image/my.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "malay" })}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("id")}
            >
              <img
                src={toAbsoluteUrl("/image/id.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "indo" })}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <div
          style={{
            backgroundColor: "#1c1e23",
            color: "white",
            padding: "10px",
          }}
        >
          <div className="text-font4">{intl.formatMessage({ id: "tnc" })}</div>
          <div className="custom-border-bottom mb-2" />
          <div className="text-muted text-font2">
            <div className="fw-bold mb-2">
              {intl.formatMessage({ id: "userAgreement" })} :
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "userAgreementDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "accountRegistration" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "accountRegistrationDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "accountRegistrationDscription_2" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "accountRegistrationDscription_3" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "accountRegistrationDscription_4" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "depositsNWithdrawals" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "depositsNWithdrawalsDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "depositsNWithdrawalsDscription_2" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "depositsNWithdrawalsDscription_3" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bettingRules" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bettingRulesDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bettingRulesDscription_2" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bettingRulesNoteTitle_1" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_1" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_2" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_3" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bettingRulesNoteTitle_2" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_4" })}
            </div>
            <div className="mb-1 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_5" })}
            </div>
            <div className="mb-2 d-flex">
              <i className="bi bi-dot"></i>
              {intl.formatMessage({ id: "bettingRulesNoteDscription_6" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bonusesNPromotions" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bonusesNPromotionsDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "bonusesNPromotionsDscription_2" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "bonusesNPromotionsDscription_3" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "playResponsibly" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "playResponsiblyDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "playResponsiblyDscription_2" })}
            </div>
            <div className="mb-2">
              {intl.formatMessage({ id: "playResponsiblyDscription_3" })}
            </div>
            <div className="mb-1">{intl.formatMessage({ id: "others" })}</div>
            <div className="mb-1">
              {intl.formatMessage({ id: "othersDscription_1" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "othersDscription_2" })}
            </div>
            <div className="mb-1">
              {intl.formatMessage({ id: "othersDscription_3" })}
            </div>
            <div className="mb-3">
              {intl.formatMessage({ id: "othersDscription_4" })}
            </div>
          </div>
          <div
            className="text-font2"
            onClick={closeDialog}
            style={{
              width: "30%",
              backgroundColor: "#f5be09",
              border: "1px solid #f5be09",
              margin: "0 auto",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <div style={{ padding: "5px", color: "black" }}>
              {intl.formatMessage({ id: "close" })}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <div
          className="text-font3"
          style={{
            padding: "20px",
            backgroundColor: "#1c1e23",
            color: "white",
          }}
        >
          <p> {intl.formatMessage({ id: "openNewRedirectTab" })}</p>
          <div
            style={{ color: "#f5be09", textAlign: "end" }}
            onClick={handlNew}
          >
            {intl.formatMessage({ id: "confirm" })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const styles = {
  navBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    backgroundColor: "black",
  },
  backButton: {
    position: "absolute",
    left: "10px",
    fontSize: "20px",
  },
  title: {
    fontWeight: "bold",
    color: "white",
  },
};
