import { useRef } from "react";
import { Modal } from "../../../../common/components/Modal";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AddLoginAccForm } from "./AddLoginAccForm";
import { addLoginAcc, loginChoose } from "../../../api/loginapi";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";

export const AddLoginAccDialog = ({ setSwalProps, setMemberTelegramLists }) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const formikRef = useRef();

  const dispatch = useDispatch();

  const saveValues = (queryParams) => {
    addLoginAcc(queryParams).then((resp) => {
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "addAccSuccessfully" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
          },
          onResolve: () => {
            const queryParams = {
              action: "membertelegramvisit",
              telegramid: sessionStorage.getItem("telegramID") || "",
            };
            loginChoose(queryParams).then((res) => {
              setMemberTelegramLists(res.memberTelegramLists || []);
            });
            setSwalProps({});
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="addLoginModal"
      title={intl.formatMessage({ id: "addAcc" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <AddLoginAccForm
          formikRef={formikRef}
          saveValues={saveValues}
          setSwalProps={setSwalProps}
        />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        <button
          type="button"
          className="btn btn-prim fw-bold px-3 text-font2"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
