import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as actions from "../../../../redux/SettingAction";
import {
  CheckIsNull,
  ResolveArrayNull,
} from "../../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { DepositGatewayForm } from "./DepositGatewayForm";
import { ApiStatusTypeEnum } from "../../../../../../enumeration/ApiStatusTypeEnum";
import { Dialog } from "@mui/material";

export const DepositGatewayDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const receiptRef = useRef();
  const isInitialRef = useRef(true);
  const intl = useIntl();
  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const [open, setOpen] = useState(false);
  const [newPayment, setNewPayment] = useState("");
  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getDepositWalletUI({ action: "topupwalletui" })).then(
      (resp) => {
        resp = resp.data;
        setDepositToLastGame(!CheckIsNull(resp.companycode));
      }
    );
  }, []);

  useEffect(() => {
    dispatch(
      actions.getGatewayDepositUI({
        action: "topupgatewayui",
        methodcode: selectedGateway?.methodcode ?? "",
      })
    );
  }, [selectedGateway]);

  const handleClose = () => {
    setOpen(false);
  };
  const handlNew = () => {
    console.log(newPayment);
    window.open(newPayment);
    setOpen(false);
  };

  const saveValues = (queryParams) => {
    const newQueryParams = {
      action: "gatewaycreatebill",
      methodcode: selectedGateway.methodcode,
      type: CheckIsNull(selectedBank) ? "" : selectedBank.type ?? "",
      bankcode: CheckIsNull(selectedBank) ? "" : selectedBank.code ?? "",
      amount: queryParams.amount,
      platform: "mobile",
      companycode: !CheckIsNull(companyCode)
        ? companyCode
        : depositToLastGame &&
          !CheckIsNull(settingState.depositWalletUI.companycode)
        ? settingState.depositWalletUI.companycode
        : "",
    };

    dispatch(actions.submitGatewayDepositRequest(newQueryParams)).then(
      (resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setNewPayment(resp.paymentlink);
          if (sessionStorage.getItem("platform") === "mobile") {
            const paymentLink = resp.paymentlink;
            console.log(`paymentGateway:${paymentLink}`);
            sessionStorage.setItem("paymentLink", paymentLink);
            // navigate("/setting/deposit/payment");
          } else {
            const isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );
            if (isSafari || sessionStorage.getItem("platform") === "telegram") {
              setOpen(true);
            } else {
              window.open(resp.paymentlink);
            }
          }
          formikRef.current.resetForm();
          if (dismissBtnRef) {
            dismissBtnRef.current.click();
          }
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (settingState.depositGatewayUI && isInitialRef.current) {
      if (
        ResolveArrayNull(settingState.depositGatewayUI.topupGatewayMethods)
          .length > 0
      ) {
        const [gateway] = settingState.depositGatewayUI.topupGatewayMethods;
        setSelectedGateway(gateway);
      }
      if (
        ResolveArrayNull(settingState.depositGatewayUI.topupGatewayUIs).length >
        0
      ) {
        const [bank] = settingState.depositGatewayUI.topupGatewayUIs;
        setSelectedBank({ ...bank, index: 0 });
      }
      isInitialRef.current = false;
    }
  }, [settingState.depositGatewayUI]);

  return (
    <>
      {settingState.depositGatewayUI && settingState.depositWalletUI ? (
        <>
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "gateway" })}
          </div>
          <div className="d-flex mb-2 text-font2">
            {settingState.depositGatewayUI.topupGatewayMethods.map(
              (gateway, index) => (
                <div key={gateway.methodcode} className="cursor-pointer me-4">
                  <div
                    onClick={() => {
                      setSelectedGateway(gateway);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item`}
                      style={{
                        background:
                          selectedGateway.methodcode === gateway.methodcode
                            ? "#f5be09"
                            : "#2e323a",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            selectedGateway.methodcode === gateway.methodcode
                              ? "black"
                              : "white",
                          textAlign: "center",
                        }}
                      >
                        {gateway.methoddscp}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {ResolveArrayNull(settingState.depositGatewayUI.topupGatewayUIs)
            .length > 0 && (
            <>
              <div className="text-input-label mb-2 text-font3">
                {intl.formatMessage({ id: "chooseABank" })}
              </div>
              <div className="row">
                {settingState.depositGatewayUI.topupGatewayUIs.map(
                  (bank, index) => (
                    <div
                      key={bank.code}
                      className="col-md-3 mb-2"
                      style={{ width: "50%", margin: "0 auto", color: "white" }}
                    >
                      <div
                        className={`card cursor-pointer ${
                          selectedBank.index === index ? "bank-active" : ""
                        }`}
                        style={{
                          color:
                            selectedBank.index === index ? "#f5be09" : "white",
                          background: "#1c1e23",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{ background: "rgba(0,0,0,0.2)" }}
                          onClick={() => {
                            setSelectedBank({ ...bank, index });
                          }}
                        >
                          <div
                            className={`d-flex flex-column align-items-center menu-item ${
                              selectedBank.index === index ? "show fw-bold" : ""
                            }`}
                          >
                            <img
                              src={bank.imageurl}
                              alt={bank.name}
                              style={{ width: "100px" }}
                            />
                            <div
                              className="d-flex align-items-center justify-content-center small"
                              style={{ height: "40px" }}
                            >
                              <div className="text-center text-font2">
                                {bank.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}

          <div className="my-4">
            <DepositGatewayForm
              formikRef={formikRef}
              receiptRef={receiptRef}
              saveValues={saveValues}
            />
          </div>
          {settingState.depositWalletUI &&
            CheckIsNull(companyCode) &&
            !CheckIsNull(settingState.depositWalletUI.content) && (
              <div className="form-check text-font2">
                <input
                  className="form-check-input"
                  id="depositWalletUI"
                  type="checkbox"
                  checked={depositToLastGame}
                  onChange={(e) => {
                    setDepositToLastGame(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="depositWalletUI"
                  dangerouslySetInnerHTML={{
                    __html: settingState.depositWalletUI.content.replace(
                      /\*(.*?)\*/g,
                      "<span class='text-danger fw-bold text-font3'>$1</span>"
                    ),
                  }}
                />
              </div>
            )}
          <button
            type="button"
            disabled={settingState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center text-font3">
              {settingState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
          <Dialog open={open} onClose={handleClose}>
            <div
              className="text-font3"
              style={{
                padding: "20px",
                backgroundColor: "#1c1e23",
                color: "white",
              }}
            >
              <p> {intl.formatMessage({ id: "openNewTab" })}</p>
              <div
                style={{ color: "#f5be09", textAlign: "end" }}
                onClick={handlNew}
              >
                {intl.formatMessage({ id: "confirm" })}
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <Skeleton className="w-100" height="500px" />
      )}
    </>
  );
};
