import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import {
  Input,
  Input2,
} from "../../../../common/components/control/InputField";

export const AddLoginAccForm = ({ formikRef, saveValues, setSwalProps }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        confirmPassword: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      validate={(values, formikHelpers) => {
        // const errors = { otherBankName: "" };
        // if (bankName === "Other" && CheckIsNull(values.otherBankName)) {
        //   errors.otherBankName = intl.formatMessage({
        //     id: "otherBankNameRequired",
        //   });
        //   return errors;
        // }
      }}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          action: "membertelegrambind",
          telegramid: sessionStorage.getItem("telegramID") || "",
          username: values.username,
          password: values.password,
        };
        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div>
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "username" })}
            </label>
            <div className="mb-2 position-relative">
              <Field
                name="username"
                component={Input}
                placeholder={intl.formatMessage({ id: "username" })}
                autoComplete="off"
                type="text"
              />
            </div>
          </div>
          <label className="mb-2 text-input-label" style={{ color: "white" }}>
            {intl.formatMessage({ id: "password" })}
          </label>
          <div className="mb-2 position-relative">
            <Field
              name="password"
              component={Input2}
              placeholder={intl.formatMessage({ id: "password" })}
              autoComplete="off"
              type="password"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
