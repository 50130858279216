import { useState, useEffect } from "react";
import SweetAlert2 from "react-sweetalert2";
import { LoginTab } from "../enum/LoginTab";
import { RegisterMethodTab } from "./loginTab/RegisterMethodTab";
import { RegisterTab } from "./loginTab/RegisterTab";
import { LoginFormTab } from "./loginTab/LoginFormTab";
import { ForgetPasswordTab } from "./loginTab/ForgotPasswordTab";
import { LoginPhoneAuthTab } from "./loginTab/LoginPhoneAuthTab";
import { GoogleRegisterTab } from "./loginTab/GoogleRegisterTab";
import { FiArrowLeft } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { LoginChooseTab } from "./loginTab/LoginChoose";

export const LoginRegisterDialog = ({ initialTab }) => {
  const platform = sessionStorage.getItem("platform");
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [loginTab, setLoginTab] = useState(
    initialTab
      ? initialTab
      : platform === "telegram"
      ? LoginTab.LOGIN_CHOOSE
      : LoginTab.LOGIN
  );
  const [tempAuth, setTempAuth] = useState();
  const { credential } = useParams();

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${process.env.PUBLIC_URL}/image/loginbackground.png)`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  const handleBack = () => {
    const backTab =
      loginTab === LoginTab.REGISTER_PART_1 ||
      loginTab === LoginTab.GOOGLE_REGISTER
        ? LoginTab.REGISTER_OPTION
        : loginTab === LoginTab.REGISTER_PART_2
        ? LoginTab.REGISTER_PART_1
        : loginTab === LoginTab.FORGOT_PASSWORD_PART_2
        ? LoginTab.FORGOT_PASSWORD_PART_1
        : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2
        ? LoginTab.LOGIN_PHONE_AUTH_STEP_1
        : platform === "telegram"
        ? LoginTab.LOGIN_CHOOSE
        : LoginTab.LOGIN;

    if (
      loginTab === LoginTab.REGISTER_PART_3 ||
      loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ||
      loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2
    ) {
      setSwalProps({
        show: true,
        icon: "info",
        title: intl.formatMessage({ id: "alert" }),
        text: intl.formatMessage({
          id: "backErrorDescription",
        }),
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: intl.formatMessage({ id: "confirm" }),
        denyButtonText: intl.formatMessage({ id: "cancel" }),
        onConfirm: () => {
          setLoginTab(
            loginTab === LoginTab.REGISTER_PART_3
              ? LoginTab.REGISTER_OPTION
              : platform === "telegram"
              ? LoginTab.LOGIN_CHOOSE
              : LoginTab.LOGIN
          );
        },
      });
    } else {
      setLoginTab(backTab);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-item-stretch w-100 h-100">
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
      />
      <div>
        {loginTab !== LoginTab.LOGIN && loginTab !== LoginTab.LOGIN_CHOOSE && (
          <div style={{ marginLeft: "20px", marginTop: "20px" }}>
            <button
              onClick={handleBack}
              style={{ background: "none", border: "none" }}
            >
              <FiArrowLeft size={25} style={{ color: "white" }} />
            </button>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <div
          className={loginTab === LoginTab.LOGIN_CHOOSE ? "w-100" : "col-10"}
        >
          {loginTab === LoginTab.LOGIN_CHOOSE ? (
            <LoginChooseTab
              setSwalProps={setSwalProps}
              setLoginTab={setLoginTab}
              setTempAuth={setTempAuth}
              credential={credential}
            />
          ) : loginTab === LoginTab.LOGIN ? (
            <LoginFormTab
              setSwalProps={setSwalProps}
              setLoginTab={setLoginTab}
              setTempAuth={setTempAuth}
              credential={credential}
            />
          ) : loginTab === LoginTab.REGISTER_OPTION ? (
            <RegisterMethodTab setLoginTab={setLoginTab} />
          ) : loginTab === LoginTab.REGISTER_PART_1 ||
            loginTab === LoginTab.REGISTER_PART_2 ||
            loginTab === LoginTab.REGISTER_PART_3 ? (
            <RegisterTab
              setLoginTab={setLoginTab}
              setSwalProps={setSwalProps}
              loginTab={loginTab}
            />
          ) : loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ||
            loginTab === LoginTab.FORGOT_PASSWORD_PART_2 ||
            loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ? (
            <ForgetPasswordTab
              setLoginTab={setLoginTab}
              setSwalProps={setSwalProps}
              loginTab={loginTab}
            />
          ) : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1 ||
            loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2 ? (
            <LoginPhoneAuthTab
              setLoginTab={setLoginTab}
              setSwalProps={setSwalProps}
              loginTab={loginTab}
              tempAuth={tempAuth}
            />
          ) : loginTab === LoginTab.GOOGLE_REGISTER ? (
            <GoogleRegisterTab
              setLoginTab={setLoginTab}
              setSwalProps={setSwalProps}
              loginTab={loginTab}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
